import { ajvResolver } from '@hookform/resolvers/ajv';
import { zodResolver } from '@hookform/resolvers/zod';
import { LowCarbonTech } from '@payaca/types/deviceTypes';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { BadgeColourVariant } from '@payaca/types/plBadge';
import { JSONSchemaType } from 'ajv';
import { diff as deepDiff } from 'deep-object-diff';
import { isEqual, merge, omit } from 'lodash-es';
import { useEffect } from 'react';
import { Control, FieldError, get, Resolver, useWatch } from 'react-hook-form';
import { z } from 'zod';
import { FormSection } from '../../../api/rest/connectionApplication/useGetConnectionApplicationSchema';

import usePatchConnectionApplication from '@/api/rest/connectionApplication/usePatchConnectionApplication';
import { GetConnectionApplicationQuery } from '@/gql/graphql';
import { FieldValidationState } from '@payaca/components/plField/Context';
import { sentenceCase } from '@payaca/utilities/stringUtilities';

export const nonJsonSchemaKeys = [
  'combineProperties',
  'fixedInputs',
  'formClass',
  'json_schema_extra',
  'order',
  'recognisedInputId',
  'subtitle',
  'title',
  'tile',
];

export const defaultFormValues = {
  additionalAttachments: {
    projectFileIDs: [],
  },
  devicesToInstall: [{ deviceType: null }],
};

// These sections can't be validated with Ajv so we remove, to manually write Zod schema for them.
type RemovedFields = { [k: string]: true | RemovedFields };

const removedFields: RemovedFields = {
  // Can't be validated with Ajv so we remove, to manually write Zod schema for them.
  existingDevices: true,
  devicesToInstall: true,
  // We have a custom Zod step since we are not immediately uploading images to the server
  cutOutImages: true,
  additionalAttachments: true,
  // Our address component returns a structured object of address properties, which we will later
  // flatten into these fields
  installerCustomerDetails: {
    mainAddress: true,
    installationPostCode: true,
  },
  supplyDetails: {
    exportLimitDeviceDetails: true,
  },
};

const FieldSetSorting: Record<string, string[]> = {
  existingDevices: [
    'deviceClass',
    'deviceSysRef',
    'enaLctDataset',
    'powerFactor',
    'isBeingRemoved',
  ],
  devicesToInstall: [
    'deviceClass',
    'deviceSysRef',
    'targetInstallDate',
    'generatorType',
    'energyStorageCapacity',
    'powerFactor',
  ],
};

const fieldRequiredMsg = 'This field is required';

export const DevicesFieldArrayKeys = ['devicesToInstall', 'existingDevices'];

export const ignoredJsonExtraTitles = ['USER ACCOUNT DERIVED INPUT']; // ignore the json_schema_extra title for these types

export const prepareFormSchema = (formSchema: any): any => {
  formSchema = structuredClone(formSchema); // Avoid mutating prop
  if (!formSchema.combineProperties) return formSchema;
  Object.values(formSchema.combineProperties).forEach((combine: any) => {
    const [combineInto, combineFrom] = combine.combines;
    const combineFromProperties = formSchema.properties[combineFrom].properties;
    Object.values(combineFromProperties).forEach((property: any) => {
      // We still have to submit these fields in the namespace they were provided in
      property.json_schema_extra.namespaceOverride = combineFrom;
    });
    formSchema.properties[combineInto].properties = {
      ...formSchema.properties[combineInto].properties,
      ...formSchema.properties[combineFrom].properties,
    };
    delete formSchema.properties[combineFrom];
  });
  return formSchema;
};

export const prepareJSONSchema = (formSchema: any): JSONSchemaType<any> => {
  formSchema = structuredClone(formSchema); // Avoid mutating prop
  let schema = removeFormSchemaFromJSONSchema(formSchema);
  schema = removeSpecialCasesFromJSONSchema(schema);
  return schema;
};

const removeFormSchemaFromJSONSchema = (schema: any): JSONSchemaType<any> => {
  const enhanced = enhanceJSONSchema(schema);
  const cleaned: any = omit(enhanced, nonJsonSchemaKeys);
  cleaned.properties = removeFormSchemaFromJSONSchemaProperties(
    schema.properties
  );

  return cleaned;
};

const enhanceJSONSchema = (schema: any): JSONSchemaType<any> => {
  for (const subSchema of Object.values<any>(schema.properties)) {
    if (!subSchema.properties) continue;
    Object.entries<any>(subSchema.properties).forEach(([key, value]) => {
      if (
        value.json_schema_extra?.required === true &&
        value.type === 'string'
      ) {
        // Without this, empty strings are considered valid
        value.minLength = 1;
      }
      if (value.type === 'string' && value.json_schema_extra?.pattern) {
        const { value: pattern, message } = value.json_schema_extra.pattern;
        value.pattern = pattern.replace('\\#', '#'); // Fixes their invalid regex bug!
        value.errorMessage = {
          pattern: message,
        };
      }
      if (key === 'mpan') {
        value.pattern = '^\\d{13}$';
        value.errorMessage = {
          pattern: 'Invalid MAPN entered. Please enter a 13 digit number.',
        };
      }
    });
  }

  return schema;
};

const removeFormSchemaFromJSONSchemaProperties = (properties: any) => {
  const cleaned = Object.fromEntries(
    Object.entries<any>(properties).map(([key, value]) => {
      const cleanedValue: any = omit(value, nonJsonSchemaKeys);
      if (typeof value.properties === 'object')
        cleanedValue.properties = removeFormSchemaFromJSONSchemaProperties(
          value.properties
        );
      if (value.items?.oneOf)
        cleanedValue.items.oneOf = value.items.oneOf.map((item: any) =>
          removeFormSchemaFromJSONSchema(item)
        );

      return [key, cleanedValue];
    })
  );
  return cleaned;
};

const removeSpecialCasesFromJSONSchema = (
  schema: any,
  fields: RemovedFields = removedFields
): JSONSchemaType<any> => {
  schema.properties = Object.fromEntries(
    Object.entries<any>(schema.properties)
      // Remove entire property
      .filter(([key]) => fields[key] !== true)
      // Recurse into sub-schema, potentially removing properties within it
      .map(([key, subSchema]) => {
        if (!fields[key] || fields[key] === true) return [key, subSchema];
        return [key, removeSpecialCasesFromJSONSchema(subSchema, fields[key])];
      })
  );
  schema.required = schema.required.filter(
    (key: string) => fields[key] !== true
  );

  return schema;
};

const commonDeviceSchema = z.object({
  powerFactor: z.number().gte(0).lte(1).step(0.0001),
  deviceSysRef: z.string(),
});
const commonExportLimitDeviceDetailsSchema = z.object({
  exportLimitSLDAttachment: z.object({
    projectFileID: z.string().min(1, fieldRequiredMsg),
  }),
});

const baseZodSchema = z
  .object({
    installerCustomerDetails: z.object({
      mainAddress: z.object({
        line1: z.string().min(1, fieldRequiredMsg),
        line2: z.string().optional(),
        city: z.string().min(1, fieldRequiredMsg),
        postcode: z.string().min(5),
      }),
    }),
    cutOutImages: z.object({
      projectFileID: z.string().min(1, fieldRequiredMsg),
    }),
    additionalAttachments: z.object({
      projectFileIDs: z.array(z.string()),
    }),
    existingDevices: z
      .discriminatedUnion('deviceClass', [
        commonDeviceSchema.extend({
          deviceClass: z.literal('EXISTING_GEN_ENA_REGISTERED'),
          deviceType: z.enum(['BATTERY', 'SOLAR_PV', 'V2G_INVERTER']),
          isBeingRemoved: z.boolean(),
        }),
        commonDeviceSchema.extend({
          deviceClass: z.literal('EXISTING_DEMAND_ENA_REGISTERED'),
          deviceType: z.enum(['HP', 'EVCP_DC', 'EVCP_AC']),
          isBeingRemoved: z.boolean(),
        }),
      ])
      .array(),
    devicesToInstall: z
      .discriminatedUnion('deviceClass', [
        commonDeviceSchema.extend({
          deviceClass: z.literal('DEMAND_DEVICE_GENERAL_ENA_REGISTERED'),
          deviceType: z.enum(['EVCP_AC', 'EVCP_DC']),
          targetInstallDate: z.string(),
        }),
        commonDeviceSchema.extend({
          deviceClass: z.literal('DEMAND_DEVICE_HP_ENA_REGISTERED'),
          deviceType: z.enum(['HP']),
          targetInstallDate: z.string(),
        }),
        commonDeviceSchema.extend({
          deviceClass: z.literal('GEN_DEVICE_GENERAL_ENA_REGISTERED'),
          deviceType: z.enum(['BATTERY', 'SOLAR_PV', 'V2G_INVERTER']),
          targetInstallDate: z.string(),
        }),
      ])
      .array()
      .nonempty(),
  })
  .passthrough(); // Ignore unrecognised keys, since Ajv is doing most of them

const generationZodSchema = z
  .object({
    supplyDetails: z
      .object({
        isExportLimitDevicePresent: z.boolean(),
        exportLimitDeviceDetails: z
          .discriminatedUnion('exportLimitDeviceClass', [
            commonExportLimitDeviceDetailsSchema.extend({
              exportLimitDeviceClass: z.literal('G100'),
              g100ExportLimitDeviceSysRef: z.string(),
            }),
            commonExportLimitDeviceDetailsSchema.extend({
              exportLimitDeviceClass: z.literal('Manufacturer'),
              registeredCapacity: z.number(),
              phaseCode: z.number().positive(),
            }),
            commonExportLimitDeviceDetailsSchema.extend({
              exportLimitDeviceClass: z.literal('Other'),
              registeredCapacity: z.number(),
              exportLimitDeviceDescription: z.string().min(1, fieldRequiredMsg),
              phaseCode: z.number().positive(),
            }),
          ])
          .optional(),
      })
      .refine(
        (data) => {
          if (data.isExportLimitDevicePresent) {
            return !!data.exportLimitDeviceDetails;
          }
          return true; // No validation needed when isExportLimitDevicePresent is false
        },
        {
          message: 'This field is required',
          path: ['exportLimitDeviceClass'],
        }
      ),
  })
  .passthrough();

const generateZodSchema = (jsonSchema: JSONSchemaType<any>) => {
  let schema = baseZodSchema;
  if (
    jsonSchema.properties.supplyDetails.required.includes(
      'isExportLimitDevicePresent'
    )
  ) {
    schema = baseZodSchema.merge(generationZodSchema);
  }
  return zodResolver(schema);
};

export const jsonSchemaWithZodEdgeCasesResolver: (
  jsonSchema: JSONSchemaType<any>
) => Resolver<any, any> = (jsonSchema) => {
  const jsonSchemaResolver = ajvResolver(jsonSchema, {
    formats: {
      email: /^.+@.+$/,
    },
  });
  const edgeCasesResolver = generateZodSchema(jsonSchema);

  return async (values: any, ...args) => {
    const results = await Promise.all([
      jsonSchemaResolver(values, ...args),
      edgeCasesResolver(values, ...args),
    ]);
    return merge(...results);
  };
};
/**
 * Get the result of a mapping reference within a schema
 * @param mappingPath i.e. #/definitions/ExistingGenDevicesInDatabaseV1
 * @param schema { defintions: { ExistingGenDevicesInDatabaseV1: { ... } } }
 */
export const resolveMappingRef = (
  schema: Record<string, any>,
  mappingPath?: string
) => {
  if (!mappingPath) {
    return;
  }
  const parts = mappingPath
    .split('/')
    .filter((f: any) => Boolean(f) && f !== '#'); // Split path and filter out empty strings/# symbol
  let result = schema;
  parts.forEach((part: any) => {
    result = result[part];
  });

  return result;
};

/**
 * Sort data properties object keys, so fields are rendered in a specific order
 */
export const getSortedFieldSetDataProperties = (
  properties: Record<string, any>,
  namespace?: string
) => {
  if (!namespace) {
    return properties;
  }
  const splitNamespace = namespace.split('.');
  const firstKey = splitNamespace[0];
  if (!(firstKey in FieldSetSorting)) {
    return properties;
  }
  const sortingDefinitionArray = FieldSetSorting[firstKey];
  const propertyKeys = Object.keys(properties);
  propertyKeys.sort((a, b) => {
    const aIndex = sortingDefinitionArray.indexOf(a);
    const bIndex = sortingDefinitionArray.indexOf(b);
    return aIndex - bIndex;
  });
  const sortedDataProperties: any = {};
  propertyKeys.forEach((key: string) => {
    sortedDataProperties[key] = properties[key];
  });
  // add any missing keys not defined in the sort at the end, so we don't lose any data if schema changes
  const unsortedKeys = propertyKeys.filter(
    (x) => !sortingDefinitionArray.includes(x)
  );
  unsortedKeys.forEach((key: string) => {
    sortedDataProperties[key] = properties[key];
  });

  return sortedDataProperties;
};

const validationMessages = (fieldError: FieldError) => {
  switch (fieldError.type) {
    // These are Zod errors where it has a nice user focussed output we can use
    case 'too_big':
    case 'not_multiple_of':
    case 'errorMessage':
      return [fieldError.message || 'Unknown Error'];
    case 'invalid_union_discriminator':
      return ['Invalid format'];
    case 'too_small':
      return ['This field is required'];
    // These all Ajv errors where its not user focussed so we need to overwrite
    case 'required':
    case 'minLength':
    case 'type':
    case 'invalid_type':
      return ['This field is required'];
    case 'format':
      return ['Invalid format'];
    case 'enum':
      return ['Choose an option'];
    default:
      return [`Unknown Error: ${fieldError.type} - ${fieldError.message}`];
  }
};

export const fieldValidationState = (
  fieldError: FieldError
): FieldValidationState => {
  return {
    isValid: false,
    validationMessages: validationMessages(fieldError),
  };
};

export const fieldValidationResult = (
  fieldError: FieldError
): FieldValidationResult => {
  return {
    isValid: !fieldError,
    errors: validationMessages(fieldError),
  };
};

export function getAddressValidationState(errors: any, name: string) {
  const addressLines = ['line1', 'line2', 'city', 'postcode', 'country'];

  const addressValidationState: {
    [fieldname: string]: FieldValidationResult;
  } = {};
  const addressErrors = get(errors, name);

  if (addressErrors) {
    for (const addressLine of addressLines) {
      const error = addressErrors[addressLine];
      if (!error) continue;
      addressValidationState[addressLine] = fieldValidationResult(error);
    }
  }
  return addressValidationState;
}

export function removeUndefinedKeys(obj: any) {
  // Iterate over the object keys
  for (const key in obj) {
    // Check if the current key's value is an object
    if (
      typeof obj[key] === 'object' &&
      obj[key] !== null &&
      !Array.isArray(obj[key])
    ) {
      // Recursively remove undefined keys in nested objects
      removeUndefinedKeys(obj[key]);

      // After recursion, if the nested object is empty, delete it
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key];
      }
    }

    // If the value is undefined, delete the key
    if (obj[key] === undefined) {
      delete obj[key];
    }
  }
  return obj;
}

/**
 * Get list of device types from the devicesToInstall schema
 **/
const getDeviceTypesFromDevicesToInstall = (
  schemaSection: FormSection,
  lcts: LowCarbonTech[]
) => {
  const schemaItems = [];
  if (lcts.includes('HP')) {
    schemaItems.push(
      schemaSection.items.oneOf.find(
        (item: any) => item.title === 'DemandDatabaseToInstallHPV1' // ena db for hp
      )
    );
  }
  if (['EVCP_DC', 'EVCP_AC'].some((d: any) => lcts.includes(d))) {
    schemaItems.push(
      schemaSection.items.oneOf.find(
        (item: any) => item.title === 'DemandDatabaseToInstallGeneralV1' // ena db for non-hp
      )
    );
  }
  if (['BATTERY', 'SOLAR_PV', 'EVV2G'].some((d: any) => lcts.includes(d))) {
    schemaItems.push(
      schemaSection.items.oneOf.find(
        (item: any) => item.title === 'GenerationDeviceToInstallV1' // ena db for generation
      )
    );
  }
  const devicesSet = schemaItems
    .flatMap((db: any) => {
      if (db.properties.deviceType.enum) {
        return db.properties.deviceType.enum;
      } else if (db.properties.deviceType.allOf) {
        return db.properties.deviceType.allOf.flatMap((item: any) => item.enum);
      }
      return [];
    })
    .reduce((acc: any, x: any) => {
      if (x && lcts.includes(x)) {
        // only include deviceTypes in originally defined lcts
        acc.add(x);
      }
      return acc;
    }, new Set());

  if (lcts.includes('EVV2G')) {
    // EVV2G device types require showing V2G_INVERTER and EVCP_AC
    devicesSet.add('V2G_INVERTER');
    devicesSet.add('EVCP_AC');
  }

  return Array.from(devicesSet);
};

export const getDeviceTypesFromSchemaSection = (
  schemaSection: FormSection,
  fieldKey: string,
  lcts?: LowCarbonTech[]
) => {
  switch (fieldKey) {
    case 'devicesToInstall':
      if (lcts) {
        return getDeviceTypesFromDevicesToInstall(schemaSection, lcts);
      }
      return [];
    case 'existingDevices':
      return getDeviceTypesFromExistingDevices(schemaSection);
    default:
      return [];
  }
};

/**
 * Get list of device types from the existingDevices schema
 */
const getDeviceTypesFromExistingDevices = (schemaSection: FormSection) => {
  const schemaItems = schemaSection.items.oneOf.filter((item: any) =>
    [
      'ExistingGenDevicesInDatabaseV1',
      'ExistingDemandDevicesInDatabaseV1',
    ].includes(item.title)
  );
  const devicesSet = schemaItems
    .flatMap((db: any) =>
      db.properties.deviceType.allOf.map((x: any) => x.enum).flat()
    )
    .reduce((acc: any, x: any) => {
      if (x) {
        acc.add(x);
      }
      return acc;
    }, new Set());
  return Array.from(devicesSet);
};

export const getApplicationStatusBadgeColour = (
  status?: string
): BadgeColourVariant => {
  switch (status) {
    case 'pending':
      return 'yellow';
    case 'submitted':
      return 'blue';
    case 'complete':
      return 'teal';
    case 'failed':
      return 'red';
    default:
      return 'gray';
  }
};

export const populateFormDataWithDefaultValues = (formData: any) => {
  return {
    ...defaultFormValues,
    ...formData,
  };
};

export const usePersistFormChanges = (
  control: Control,
  persistedFormStateData: GetConnectionApplicationQuery | undefined,
  connectionApplicationId: string
) => {
  const { mutate: persistFormStateMutation } = usePatchConnectionApplication();

  const formValues = useWatch({
    control,
  });

  let changes = {};
  if (persistedFormStateData) {
    const values = structuredClone(
      persistedFormStateData.connectionApplication.data
    );
    changes = removeUndefinedKeys(deepDiff(values, formValues, true));
  }
  const hasChanges = !isEqual(changes, {});

  useEffect(() => {
    // If the form has been submitted, don't persist changes
    if (persistedFormStateData?.connectionApplication.submittedAt) {
      return;
    }

    const debounced = setTimeout(() => {
      if (!hasChanges) return;

      persistFormStateMutation({
        connectionApplicationId,
        data: changes,
      });
    }, 1000);

    return () => clearTimeout(debounced);
  }, [
    connectionApplicationId,
    formValues,
    persistFormStateMutation,
    persistedFormStateData,
  ]);

  return hasChanges;
};

export const getDeviceItemForDeviceType = (
  schemaSection: FormSection,
  deviceType: string
) => {
  return schemaSection.items.oneOf.find((item: any) => {
    const deviceTypeField = item.properties.deviceType;
    return (
      deviceTypeField.allOf?.[0] ||
      deviceTypeField.anyOf?.[0] ||
      deviceTypeField
    ).enum.includes(deviceType);
  });
};

export const getDeviceClassForDeviceType = (
  schemaSection: FormSection,
  deviceType: string
) => {
  const item = getDeviceItemForDeviceType(schemaSection, deviceType);
  return item.properties.deviceClass.enum[0];
};

export const getSchemaDataForDevice = (
  schemaSection: FormSection,
  definitions: Record<string, any>,
  watchedDeviceType: string
) => {
  // get device class for device type
  const deviceClass =
    watchedDeviceType &&
    getDeviceClassForDeviceType(schemaSection, watchedDeviceType);

  let specificDeviceClassSchema = getSchemaDataForDiscriminatorField(
    schemaSection.items.discriminator,
    definitions,
    deviceClass
  );

  if (!specificDeviceClassSchema) {
    // no mapping specified - use the current objects schema (SOLAR_PV newDevices schema)
    specificDeviceClassSchema = schemaSection.items.oneOf.find((item: any) =>
      item.properties.deviceClass.enum.includes(deviceClass)
    );
  }

  let schemaData = null;

  if (specificDeviceClassSchema) {
    schemaData = {
      ...specificDeviceClassSchema,
      // omit the deviceType field from the schema as this field is already being shown
      properties: omit(specificDeviceClassSchema.properties, 'deviceType'),
    };

    const propertyKeys = Object.keys(schemaData.properties);
    for (const propertyKey of propertyKeys) {
      if (propertyKey !== 'deviceSysRef') {
        // displayIf property so subsequent fields are only shown after deviceSysRef is set
        schemaData.properties[propertyKey].json_schema_extra.displayIf = {
          property: 'deviceSysRef',
          valueIsSet: true,
        };
      }
    }
  }
  return schemaData;
};

export const getSchemaDataForDiscriminatorField = (
  discriminatorObj: any, // discriminator object containing property and mapping refs
  definitions: Record<string, any>,
  discriminatorPropertyValue?: any
) => {
  // get the mapping path for the selected value
  const mappingPath =
    discriminatorPropertyValue &&
    discriminatorObj.mapping[discriminatorPropertyValue];

  // get the schema for the selected value
  let discriminatorSchemaData;
  if (mappingPath) {
    discriminatorSchemaData = resolveMappingRef(
      {
        definitions,
      },
      mappingPath
    );
  }
  return discriminatorSchemaData;
};

export const getFieldLabelFromFieldSchema = (fieldSchema: {
  json_schema_extra: { type: string; required: boolean; title?: string };
  title: string;
}) => {
  const title = sentenceCase(
    (!ignoredJsonExtraTitles.includes(fieldSchema.json_schema_extra.type) &&
      fieldSchema.json_schema_extra?.title) ||
      fieldSchema.title
  );
  return `${title}${fieldSchema.json_schema_extra.required ? ' *' : ''}`;
};

export const isMultipleFileSelection = (fieldSchema: {
  json_schema_extra: { type: string };
  maxItems?: number;
}) => {
  return (
    (fieldSchema?.maxItems && fieldSchema.maxItems > 1) ||
    (fieldSchema.maxItems == null &&
      (!fieldSchema.json_schema_extra.type ||
        !(fieldSchema.json_schema_extra.type === 'SINGLE ATTACHMENT INPUT 01')))
  );
};

/**
 * Get all the field keys which are dependent on the current field
 */
export const getDependentFields = (
  data: any,
  field: string,
  fieldNamespace?: string
): string[] => {
  // these can then be cleared when the current field changes
  const dependenentFields: string[] = [];
  Object.entries<{
    json_schema_extra: { displayIf: null | { property: string } };
  }>(data.properties).forEach(([key, value]) => {
    const isDependent =
      value.json_schema_extra.displayIf?.property &&
      value.json_schema_extra.displayIf.property === field;
    if (isDependent) {
      dependenentFields.push([fieldNamespace, key].filter((x) => x).join('.'));
    }
  });
  return dependenentFields;
};
