import clsx from 'clsx';
import { nanoid } from 'nanoid';
import React, { ComponentPropsWithoutRef, useContext, useRef } from 'react';

import { FieldContext } from '../plField/Context';

export interface IRadioProps
  extends Omit<ComponentPropsWithoutRef<'input'>, 'type' | 'id' | 'name'> {
  label?: string;
  hiddenLabel?: boolean;
}

const Radio = React.forwardRef<HTMLInputElement, IRadioProps>((props, ref) => {
  const { className, disabled, label, hiddenLabel = false, ...rest } = props;
  const { name } = useContext(FieldContext);
  const { current: uId } = useRef(nanoid());

  return (
    <div className="prose flex items-center">
      <input
        ref={ref}
        id={uId}
        name={name}
        className={clsx(
          'pl-form-radio',
          className,
          disabled ? ' cursor-not-allowed' : ' cursor-pointer'
        )}
        type="radio"
        disabled={disabled}
        {...rest}
      />
      {label && (
        <label
          htmlFor={uId}
          className={clsx(
            'ms-3',
            hiddenLabel && 'sr-only',
            disabled ? ' cursor-not-allowed opacity-50' : ' cursor-pointer'
          )}
        >
          {label}
        </label>
      )}
    </div>
  );
});

export default Radio;
