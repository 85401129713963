import UntitledIcon, { TIconName } from '@payaca/untitled-icons';
import {
  ComponentPropsWithRef,
  forwardRef,
  FunctionComponent,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useMountedState } from 'react-use';

import { FieldContext } from '../plField/Context';
import { clstx } from '../utils';

export type InputSizeVariant = 'sm' | 'md' | 'lg';

export type TProps = ComponentPropsWithRef<'input'> & {
  sizeVariant?: InputSizeVariant;
  leadingElement?: ReactNode;
  className?: string;
  inputClassName?: string;
  wrapperProps?: Omit<ComponentPropsWithRef<'div'>, 'className'>;
};

/**
 * A basic text input component. It passes all input element props through to the underlying input element,
 * unmodified. This makes it suitable for uncontrolled inputs (e.g. react-hook-form)
 */
// @ts-expect-error - forwardRef is not typed correctly
const RawInput: FunctionComponent<TProps> = forwardRef<
  HTMLInputElement,
  TProps
>(
  (
    {
      sizeVariant = 'md',
      leadingElement,
      disabled,
      className,
      inputClassName,
      style,
      type = 'text',
      wrapperProps,
      ...rest
    },
    ref
  ) => {
    const { id, name, validationState } = useContext(FieldContext);

    const [leadingItemWidth, setLeadingItemWidth] = useState<number>();

    const isMounted = useMountedState();

    const leadingItemRef = useCallback(
      (node: any) => {
        if (!node) return;

        setTimeout(() => {
          if (isMounted()) {
            setLeadingItemWidth(node.offsetWidth);
          }
        }, 0);
      },
      [isMounted, leadingElement]
    );

    return (
      <div
        className={clstx(
          'relative overflow-hidden focus-within:border-blue-500 focus-within:ring-1 focus-within:ring-blue-500 border border-solid border-gray-200 bg-white rounded-lg',
          disabled && 'pointer-events-none opacity-70',
          validationState?.isValid === true && 'border-teal-500',
          validationState?.isValid === false && 'border-red-500',
          className
        )}
        {...wrapperProps}
      >
        <input
          disabled={disabled}
          className={clstx(
            `border-0 bg-transparent block w-full text-base focus-visible:outline-0 disabled:pointer-events-none disabled:opacity-50`,
            sizeVariant === 'sm' && 'py-2 px-3',
            sizeVariant === 'md' && 'py-3 px-4',
            sizeVariant === 'lg' && 'py-3 px-4 sm:p-5',
            inputClassName
          )}
          name={name}
          id={id}
          type={type}
          style={{
            ...style,
            paddingLeft: leadingItemWidth
              ? `calc(1.33rem + ${leadingItemWidth}px)`
              : undefined,
          }}
          ref={ref}
          {...rest}
        />
        {leadingElement && (
          <div
            ref={leadingItemRef}
            className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-4 peer-disabled:pointer-events-none peer-disabled:opacity-50"
          >
            {leadingElement}
          </div>
        )}
      </div>
    );
  }
);
RawInput.displayName = 'RawInput';

export const InputIcon: FunctionComponent<{ name: TIconName }> = ({ name }) => {
  return <UntitledIcon name={name} className="h-4 w-4" />;
};

export default RawInput;
