import { Nullish } from '@payaca/utilities/types';
import { FC } from 'react';
import DatePickerPopover, {
  Props as DatePickerPopoverProps,
} from '../plDatePicker/DatePickerPopover';
import RawInput, { InputIcon, TProps as InputProps } from '../plInput/RawInput';

const defaultValueFormatter = (value: Date, showTimeSelect?: boolean) => {
  return `${value?.toLocaleDateString()} ${
    showTimeSelect
      ? value?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      : ''
  }`;
};

type Props = Omit<InputProps, 'onChange' | 'value' | 'type'> &
  Pick<DatePickerPopoverProps, 'config' | 'onChange' | 'showTimeSelect'> & {
    value?: Date | null;
    valueFormatter?: (value: Date, showTimeSelect?: boolean) => string;
    businessHours?: Nullish<(string | null)[][]>;
  };

export const DateInput: FC<Props> = ({
  onChange,
  value,
  showTimeSelect,
  valueFormatter = defaultValueFormatter,
  businessHours,
  config,
  ...rest
}) => {
  const fallbackOnChange = () => null;

  return (
    <div>
      <DatePickerPopover
        onChange={onChange || fallbackOnChange}
        defaultDate={value || new Date()}
        showTimeSelect={showTimeSelect}
        businessHours={businessHours}
        config={config}
      >
        {({ setIsOpen }) => (
          <RawInput
            leadingElement={CalendarIcon}
            {...rest}
            value={value ? valueFormatter(value, showTimeSelect) : undefined}
            onFocus={(e) => {
              setIsOpen(true);
              e.target.blur();
            }}
          />
        )}
      </DatePickerPopover>
    </div>
  );
};

const CalendarIcon = <InputIcon name="calendar" />;
