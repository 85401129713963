import { FC, useMemo } from 'react';

import { getBrandingColourContext } from '@payaca/helpers/brandingColourContextHelper';
import {
  DateFormats,
  getInternationalMomentDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';
import { getAddressAsString } from '@payaca/helpers/locationHelper';
import { DocumentSenderContext } from '@payaca/types/accountBrandTypes';
import { Address, PartialAddress } from '@payaca/types/locationTypes';
import {
  HydratedMaterialPurchaseIntent,
  HydratedMaterialPurchaseRecord,
  PurchaseOrder,
} from '@payaca/types/materialsListTypes';
import { Material } from '@payaca/types/materialTypes';
import { Supplier, SupplierAddress } from '@payaca/types/supplierTypes';
import moment from 'moment-timezone';
import FieldLabel from '../fieldLabel/FieldLabel';
import GenericDocument from '../genericDocument/GenericDocument';
import MarkdownLabel from '../markdownLabel/MarkdownLabel';
import PurchaseOrderBody, { PurchaseOrderLine } from './PurchaseOrderBody';
import './PurchaseOrderDocument.sass';

type LiveAddress = Omit<Address, 'id'>;
type SupplierLiveAddress = Omit<SupplierAddress, 'id' | 'address'> & {
  address: LiveAddress;
};
type SupplierWithLiveAddress = Omit<Supplier, 'addresses'> & {
  addresses?: SupplierLiveAddress[];
};
export type PurchaseOrderDocumentSupplier = SupplierWithLiveAddress | Supplier;
type Props = {
  project?: {
    reference?: string;
  };
  documentSenderContext: DocumentSenderContext;
  supplier?: Pick<
    PurchaseOrderDocumentSupplier,
    'name' | 'emailAddress' | 'phoneNumber' | 'addresses'
  >;
  purchaseOrder: Pick<
    PurchaseOrder,
    | 'reference'
    | 'sentAt'
    | 'additionalNotes'
    | 'dueAt'
    | 'showMaterialPrices'
    | 'confirmedAt'
  >;
  materialPurchaseIntents: (Pick<
    HydratedMaterialPurchaseIntent,
    | 'materialQuantity'
    | 'supplierMaterialReference'
    | 'predictedUnitPriceExcludingTax'
    | 'predictedTotalPriceExcludingTax'
    | 'materialsListMaterialId'
  > & {
    material: Pick<Material, 'name' | 'thumbnailUrl'>;
  })[];
  materialPurchaseRecords: Pick<
    HydratedMaterialPurchaseRecord,
    | 'materialsListMaterialId'
    | 'materialQuantity'
    | 'price'
    | 'totalPriceExcludingTax'
  >[];
  address?: PartialAddress;
  orderType?: 'delivery' | 'collection';
};
const PurchaseOrderDocument: FC<Props> = ({
  documentSenderContext,
  purchaseOrder,
  supplier,
  materialPurchaseIntents,
  materialPurchaseRecords,
  address,
  project,
  orderType,
}: Props): JSX.Element | null => {
  const purchaseOrderLines = materialPurchaseIntents.map<PurchaseOrderLine>(
    (mpi) => {
      let materialPurchaseRecord;
      if (purchaseOrder.confirmedAt) {
        materialPurchaseRecord = materialPurchaseRecords.find(
          (mpr) => mpr.materialsListMaterialId === mpi.materialsListMaterialId
        );
      }

      return {
        materialName: mpi?.material?.name,
        thumbnailUrl: mpi?.material?.thumbnailUrl,
        predictedMaterialQuantity: mpi?.materialQuantity,
        supplierReference: mpi?.supplierMaterialReference,
        predictedUnitPrice: mpi?.predictedUnitPriceExcludingTax,
        predictedTotalPrice: mpi?.predictedTotalPriceExcludingTax,
        materialQuantity: materialPurchaseRecord?.materialQuantity,
        unitPrice: materialPurchaseRecord?.price || undefined,
        totalPrice: materialPurchaseRecord?.totalPriceExcludingTax,
        strikeThrough: purchaseOrder.confirmedAt
          ? !materialPurchaseRecord
          : false,
      };
    }
  );

  const keyInformation = useMemo(() => {
    const ki = [{ label: 'PO ref', value: purchaseOrder.reference.toString() }];

    if (project?.reference) {
      ki.push({ label: 'Project ref', value: project.reference });
    }

    if (purchaseOrder.sentAt) {
      ki.push({
        label: 'Issued',
        value: moment(purchaseOrder.sentAt).format(
          getInternationalMomentDateFormatByRegion(
            DateFormats.SHORT,
            documentSenderContext.region
          )
        ),
      });
    }

    if (purchaseOrder.dueAt) {
      ki.push({
        label: 'Due by',
        value: moment(purchaseOrder.dueAt).format(
          getInternationalMomentDateFormatByRegion(
            DateFormats.SHORT,
            documentSenderContext.region
          )
        ),
      });
    }

    return ki;
  }, [purchaseOrder]);

  const brandingColourContext = getBrandingColourContext(
    documentSenderContext.brandColour
  );

  const addressString = useMemo(() => {
    if (!address) return;

    const string = getAddressAsString(address);

    return string?.length ? string : null;
  }, [address]);

  if (!purchaseOrder) return null;

  return (
    <GenericDocument
      documentSenderContext={documentSenderContext}
      className="purchase-order-document"
      title="Purchase order"
      keyInformation={keyInformation}
      documentRecipient={
        supplier
          ? {
              name: supplier.name,
              address: supplier.addresses?.length
                ? supplier.addresses[0].address
                : undefined,
              emailAddress: supplier.emailAddress || undefined,
              telephoneNumber: supplier.phoneNumber || undefined,
            }
          : undefined
      }
    >
      {orderType && addressString && (
        <div>
          <h2 className="mb-2">
            {orderType === 'delivery' ? 'Deliver to' : 'Collect from'}
          </h2>{' '}
          <span>{addressString}</span>
        </div>
      )}
      <PurchaseOrderBody
        region={documentSenderContext.region}
        purchaseOrderLines={purchaseOrderLines}
        brandingColourContext={brandingColourContext}
        showMaterialPrices={purchaseOrder.showMaterialPrices || false}
      />
      {purchaseOrder.additionalNotes && (
        <div className="additional-notes">
          <FieldLabel label="Notes" />
          <MarkdownLabel markdown={purchaseOrder.additionalNotes} />
        </div>
      )}
    </GenericDocument>
  );
};

export default PurchaseOrderDocument;
