/**
 * For direct purchase orders
 * i.e. Purchase orders with City Plumbing using their API
 */
import Card from '@payaca/components/plCard/Card';
import { getDocumentSenderContextFromAccount } from '@payaca/helpers/documentSenderContextHelper';
import { useDeal } from '@payaca/store/hooks/appState';
import { HydratedPurchaseOrder } from '@payaca/types/materialsListTypes';
import { omit } from 'lodash-es';
import { FC } from 'react';
import useGetPurchaseOrderDeliveryAddress from '../../../../api/queries/purchase-orders/useGetPurchaseOrderAddress';
import useGetSupplierBranches from '../../../../api/queries/suppliers/useGetSupplierBranches';
import { useSelector } from '../../../../api/state';
import { getCityPlumbingPurchaseOrderBadgeConfig } from '../../../../helpers/purchaseOrders';
import PreviewDocument from '../../../components/PreviewQuoteInvoice/PreviewDocument';
import PurchaseOrderDocument from '../../../components/purchaseOrderDocument/PurchaseOrderDocument';
import SupplierBranchLink from '../../../components/supplierBranchLink/SupplierBranchLink';
import PurchaseOrderPageSkeletonLoader from '../PurchaseOrderPageSkeletonLoader';

type Props = {
  purchaseOrder: HydratedPurchaseOrder;
  projectId?: number;
};
const DirectPurchaseOrder: FC<Props> = (props) => {
  const { purchaseOrder, projectId } = props;
  const deal = useDeal(projectId || undefined);
  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );
  const purchaseOrderBadgeConfig = getCityPlumbingPurchaseOrderBadgeConfig(
    purchaseOrder?.metadata?.status
  );
  const orderType = purchaseOrder?.metadata?.orderType;

  const { branches } = useGetSupplierBranches(
    purchaseOrder.supplier.id.toString(),
    !!purchaseOrder.metadata?.branchId
  );

  const branch = branches?.find(
    (branch) => branch.id === purchaseOrder.metadata?.branchId
  );

  const documentSenderContext = getDocumentSenderContextFromAccount(account);

  const { data: deliveryAddress } = useGetPurchaseOrderDeliveryAddress(
    purchaseOrder?.id
  );

  const address = deliveryAddress
    ? {
        line1: deliveryAddress.line1,
        line2: deliveryAddress.line2,
        city: deliveryAddress.city,
        postcode: deliveryAddress.postalCode,
        country: deliveryAddress.country,
      }
    : purchaseOrder.metadata?.orderType === 'collection'
      ? {
          line1: branch?.address.line1,
          line2: branch?.address.line2,
          city: branch?.address.city,
          postcode: branch?.address.postcode,
          country: branch?.address.country,
        }
      : undefined;

  return (
    <PreviewDocument
      title={`Purchase Order ${purchaseOrder?.reference || ''}: ${
        purchaseOrder?.supplier?.name || ''
      }`}
      badge={{
        variant: purchaseOrderBadgeConfig.variant,
        colour: purchaseOrderBadgeConfig.colour,
        children: purchaseOrderBadgeConfig.label,
      }}
    >
      {branch && (
        <div className="mb-2">
          {orderType === 'delivery' ? 'Delivering' : 'Collection'} branch:{' '}
          <SupplierBranchLink branch={branch} />
        </div>
      )}
      {purchaseOrder ? (
        <Card>
          <Card.Body>
            <PurchaseOrderDocument
              purchaseOrder={{
                ...purchaseOrder,
                supplier: {
                  ...purchaseOrder.supplier,
                  // append any branch details onto supplier
                  emailAddress:
                    purchaseOrder.supplier.emailAddress ||
                    branch?.email ||
                    null,
                  phoneNumber:
                    purchaseOrder.supplier.phoneNumber || branch?.phone || null,
                  addresses: [
                    ...(purchaseOrder.supplier.addresses || []),
                    {
                      address: {
                        line1: branch?.address.line1 || null,
                        line2: branch?.address.line2 || null,
                        city: branch?.address.city || null,
                        country: branch?.address.country || null,
                        postcode: branch?.address.postcode || null,
                        longitude:
                          branch?.address.coordinates?.longitude || null,
                        latitude: branch?.address.coordinates?.latitude || null,
                      },
                    },
                  ],
                },
              }}
              documentSenderContext={omit(
                documentSenderContext,
                'emailAddress',
                'phoneNumber'
              )} // removing these fields as they are not relevant for direct PO
              project={
                deal
                  ? {
                      reference:
                        deal.customReference || deal.reference?.toString(),
                    }
                  : undefined
              }
              address={address}
            />
          </Card.Body>
        </Card>
      ) : (
        <PurchaseOrderPageSkeletonLoader />
      )}
    </PreviewDocument>
  );
};

export default DirectPurchaseOrder;
