import {
  autoPlacement,
  autoUpdate,
  offset,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import { Transition } from '@headlessui/react';
import { DatePickerStateProvider, DPUserConfig } from '@rehookify/datepicker';
import React, {
  createContext,
  FC,
  Fragment,
  useContext,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

import { Nullish } from '@payaca/utilities/types';
import DatePickerBody from './DatePickerBody';

type TDatePickerPopoverContext = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  businessHours?: Nullish<(string | null)[][]>;
};

const DatePickerPopoverContext = createContext<TDatePickerPopoverContext>({
  isOpen: false,
  setIsOpen: () => {
    console.log('not implemented');
  },
  businessHours: null,
});

export const useDatePickerPopoverContext = () =>
  useContext(DatePickerPopoverContext);

export type Props = {
  onChange: (date: Date) => void;
  showTimeSelect?: boolean;
  defaultDate: Date;
  children:
    | React.ReactNode
    | ((context: TDatePickerPopoverContext) => React.ReactNode);
  businessHours?: Nullish<(string | null)[][]>;
  config?: Partial<DPUserConfig>;
};
const DatePickerPopover: FC<Props> = (props) => {
  const {
    onChange,
    showTimeSelect = false,
    defaultDate,
    children,
    businessHours,
    config,
  } = props;
  const [selectedDates, onDatesChange] = useState<Date[]>(
    defaultDate ? [defaultDate] : []
  );
  const [isOpen, setIsOpen] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    transform: false,
    whileElementsMounted: autoUpdate,
    middleware: [
      autoPlacement({
        alignment: 'start',
        allowedPlacements: ['top-start', 'bottom-start'],
      }),
      offset({
        mainAxis: 15,
      }),
    ],
    open: isOpen,
    onOpenChange: setIsOpen,
  });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  const contextState = { isOpen, setIsOpen, businessHours };
  return (
    <>
      <DatePickerPopoverContext.Provider value={contextState}>
        <>
          <div ref={refs.setReference} {...getReferenceProps()}>
            {/* @ts-ignore */}
            {React.isValidElement(children) ? children : children(contextState)}
          </div>

          {createPortal(
            <Transition
              show={isOpen}
              appear
              as={Fragment}
              enter="transition duration-100 ease-out"
              enterFrom="scale-95 opacity-0"
              enterTo="scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="scale-100 opacity-100"
              leaveTo="scale-95 opacity-0"
            >
              <div
                ref={refs.setFloating}
                style={floatingStyles}
                className="z-popover block rounded-xl border bg-white shadow shadow-lg"
                {...getFloatingProps()}
              >
                {/* @ts-ignore */}
                <DatePickerStateProvider
                  config={{
                    selectedDates,
                    onDatesChange,
                    calendar: { startDay: 1 },
                    locale: {
                      options: {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      },
                    },
                    time: {
                      interval: 1,
                    },
                    ...config,
                  }}
                >
                  <DatePickerBody
                    onClose={() => setIsOpen(false)}
                    onChange={onChange}
                    showTimeSelect={showTimeSelect}
                    defaultDate={defaultDate}
                  />
                </DatePickerStateProvider>
              </div>
            </Transition>,
            document.body
          )}
        </>
      </DatePickerPopoverContext.Provider>
    </>
  );
};

export default DatePickerPopover;
