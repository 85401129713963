import { nanoid } from 'nanoid';
import {
  ChangeEventHandler,
  FocusEventHandler,
  useContext,
  useRef,
} from 'react';
import ReactMarkdown from 'react-markdown';
import { FieldContext } from '../plField/Context';
import { clstx } from '../utils';
import className = ReactMarkdown.propTypes.className;

export type TOption = {
  label: string | React.ReactNode;
  value: string;
  className?: string;
};

export type TGroupFieldBaseProps<T extends boolean> = {
  value: T extends true ? string[] : string;
  options: TOption[];
  horizontal?: boolean;
  multiple?: T;
  reverse?: boolean;
  onChange?: (value: { [key: string]: any }) => void;
  onTouch?: (fieldName: string) => void;
  emphasised?: boolean;
  hiddenInput?: boolean;
};

const GroupFieldBase = <T extends boolean>(props: TGroupFieldBaseProps<T>) => {
  const {
    options,
    value,
    multiple = false,
    horizontal = false,
    onChange,
    onTouch,
    ...rest
  } = props;
  const { name } = useContext(FieldContext);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (multiple) {
      const _value = value as string[];

      if (e.target.checked) {
        onChange?.({ [name || '']: [..._value, e.target.value] });
      } else {
        onChange?.({
          [name || '']: _value.filter((v) => v !== e.target.value),
        });
      }
    } else {
      onChange?.({ [name || '']: e.target.value });
    }
  };

  const handleTouch: FocusEventHandler<HTMLInputElement> = () => {
    onTouch?.(name || '');
  };

  return (
    <div className={'grid gap-2' + (horizontal ? ' sm:grid-cols-3' : '')}>
      {options.map((option) => (
        <GroupFieldBaseInput
          key={option.value}
          option={option}
          onChange={handleChange}
          onFocus={handleTouch}
          multiple={multiple}
          checked={
            multiple ? value.includes(option.value) : value === option.value
          }
          {...rest}
        />
      ))}
    </div>
  );
};

export type TGroupFieldBaseInputProps<T extends boolean> = Pick<
  TGroupFieldBaseProps<T>,
  'reverse' | 'multiple' | 'emphasised' | 'hiddenInput'
> & {
  checked: boolean;
  option: TOption;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onFocus: FocusEventHandler<HTMLInputElement>;
};

const GroupFieldBaseInput = <T extends boolean>(
  props: TGroupFieldBaseInputProps<T>
) => {
  const {
    option,
    reverse,
    multiple = false,
    checked,
    emphasised = false,
    hiddenInput = false,
    ...rest
  } = props;
  const { name } = useContext(FieldContext);
  const { current: uId } = useRef(nanoid());

  return (
    <label
      htmlFor={uId}
      className={clstx(
        'flex w-full cursor-pointer items-center gap-3 rounded-lg border border-gray-200 bg-white p-3 text-base dark:border-gray-700 dark:bg-slate-900 dark:text-gray-400',
        reverse && 'flex-row-reverse justify-between',
        option.className,
        emphasised && checked && 'border-blue-900 ring-1 ring-blue-900'
      )}
    >
      <input
        type={multiple ? 'checkbox' : 'radio'}
        name={name}
        className={clstx(
          multiple
            ? 'pl-form-checkbox cursor-pointer'
            : 'pl-form-radio cursor-pointer',
          hiddenInput && 'sr-only'
        )}
        id={uId}
        value={option.value}
        checked={checked}
        {...rest}
      />
      {typeof option.label === 'string' ? (
        <span className="text-base">{option.label}</span>
      ) : (
        option.label
      )}
    </label>
  );
};

export default GroupFieldBase;
