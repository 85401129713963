import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';
import { nanoid } from 'nanoid';
import {
  ComponentProps,
  FC,
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useRef,
} from 'react';
import { FieldError, get, useFormContext } from 'react-hook-form';
import { clstx } from '../utils';
import { FieldContext, FieldValidationState } from './Context';
import { FieldError as FieldErrorMessages } from './FieldError';
import FieldLegacy from './FieldLegacy';

/**
 *
 * Usage:
    <Field name="fieldName">
        <Field.Label>Field label</Field.Label>

        <Select options={[{ label: "option 1", value: 1 }]} value={1} />
    </Field>
 *
 */

type Props = {
  id?: string;
  name: string;
  className?: string;
};

const Field: FC<PropsWithChildren<Props>> = (props) => {
  const { id: customId, name, children, className } = props;

  const { current: uId } = useRef(customId || nanoid());

  /**
   * react hook form context
   */
  const {
    formState: { errors },
  } = useFormContext();

  /**
   * build legacy style `FieldValidationState` object
   */
  const fieldError = get(errors, name) as FieldError | undefined;
  const validationState: FieldValidationState | undefined =
    fieldError && !Array.isArray(fieldError)
      ? {
          isValid: false,
          validationMessages: [
            fieldError.message ||
              fieldError.root?.message ||
              `Unknown Error: ${fieldError.type}`,
          ],
        }
      : undefined;

  return (
    <FieldContext.Provider
      value={{
        id: uId,
        name,
        validationState,
      }}
    >
      <div className={clsx('prose', className)}>
        {children}

        <ErrorMessage
          name={name}
          errors={errors}
          render={({ message }) => {
            return <FieldErrorMessages messages={[message]} />;
          }}
        />
      </div>
    </FieldContext.Provider>
  );
};

const Label: FC<Omit<ComponentProps<'label'>, 'htmlFor'>> = ({
  children,
  className,
  ...rest
}) => {
  const { id } = useContext(FieldContext);

  return (
    <label htmlFor={id} className={clstx('block mb-2', className)} {...rest}>
      {children}
    </label>
  );
};

const Helper: FunctionComponent<PropsWithChildren> = ({ children }) => {
  return <div className="mt-2 text-sm text-gray-500">{children}</div>;
};

export default Object.assign(Field, {
  Legacy: FieldLegacy,
  Label,
  Helper,
});
