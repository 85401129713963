import useUpdateCustomFieldValuesForProject from '@/api/mutations/project/useUpdateCustomFieldValuesForProject';
import projectKeys from '@/api/queries/project/keyFactory';
import { registerFieldLegacyBuilder } from '@/utils/zod';
import Button from '@payaca/components/plButton/Button';
import Field from '@payaca/components/plField/Field';
import Modal, { IProps as IModalProps } from '@payaca/components/plModal/Modal';
import { CustomFieldInput } from '@payaca/custom-fields-react/components/CustomFieldInput';
import { CustomFieldDefinition } from '@payaca/custom-fields/types/index';
import { Deal } from '@payaca/types/dealTypes';
import { isNotNullish } from '@payaca/utilities/guards';
import { useQueryClient } from '@tanstack/react-query';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';

const EditCustomFieldGroupModalContent: FC<{
  fields?: CustomFieldDefinition[];
  initialValue?: Record<string, any>;
  onSubmit: (values: any) => void;
}> = (props) => {
  const { fields, initialValue, onSubmit } = props;

  const formMethods = useForm({
    defaultValues: initialValue,
    resolver: (values) => {
      const fieldErrors = fields?.reduce(
        (acc, child) => {
          const result = child.validateValue(values[child.identifier]);

          if (!result.isValid) {
            acc[child.identifier] = { message: result.validationMessages[0] };
          }

          return acc;
        },
        {} as Record<string, any>
      );

      if (fieldErrors && Object.keys(fieldErrors).length > 0) {
        return { values: {}, errors: fieldErrors };
      }

      return { values, errors: {} };
    },
  });

  const registerField = registerFieldLegacyBuilder(
    formMethods.formState.errors
  );

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit, console.error)}>
      <Modal.Body className="space-y-4">
        {fields?.map((child) => {
          return (
            <Controller
              key={child.identifier}
              defaultValue={null}
              control={formMethods.control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Field.Legacy {...registerField(child.identifier)}>
                    <Field.Label>{child.label}</Field.Label>

                    <CustomFieldInput
                      definition={CustomFieldDefinition.fromSchema(
                        child.schema
                      )}
                      value={value}
                      onChange={onChange}
                    />
                  </Field.Legacy>
                );
              }}
              name={child.identifier}
            />
          );
        })}
      </Modal.Body>

      <Modal.Footer>
        <Modal.Footer.Actions>
          <Button type="submit">Save</Button>
        </Modal.Footer.Actions>
      </Modal.Footer>
    </form>
  );
};

const EditCustomFieldsModal: FC<
  Omit<IModalProps, 'title'> & {
    projectId?: Deal['id'];
    fields?: CustomFieldDefinition[];
    initialValue?: Record<string, any>;
    fieldsetIdentifier?: string;
  }
> = (props) => {
  const {
    projectId,
    fields,
    initialValue,
    fieldsetIdentifier,
    onClose,
    ...rest
  } = props;

  /**
   * mutations
   */
  const queryClient = useQueryClient();
  const { mutateAsync: updateCustomFieldValuesMutation } =
    useUpdateCustomFieldValuesForProject();

  /**
   * callbacks
   */
  const onSubmit = async (values: any) => {
    if (!projectId || !fields) {
      return;
    }

    await updateCustomFieldValuesMutation({
      projectId: projectId.toString(),
      fieldValues: fieldsetIdentifier
        ? [
            {
              identifier: fieldsetIdentifier,
              value: JSON.stringify(values),
            },
          ]
        : Object.entries(values).map(([identifier, value]) => ({
            identifier,
            value: isNotNullish(value) ? JSON.stringify(value) : null,
          })),
    });

    await queryClient.invalidateQueries({
      queryKey: projectKeys.customFields(projectId),
    });

    onClose?.();
  };

  return (
    <Modal title="Edit" onClose={onClose} {...rest}>
      <EditCustomFieldGroupModalContent
        fields={fields}
        initialValue={initialValue}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

export default EditCustomFieldsModal;
