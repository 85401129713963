import { FieldValidationState } from '@payaca/components/plField/Context';
import Field from '@payaca/components/plField/Field';
import { InputSizeVariant } from '@payaca/components/plInput/RawInput';
import InputGroup, {
  InputGroupInput,
} from '@payaca/components/plInputGroup/InputGroup';
import { clstx } from '@payaca/components/utils';
import { FC, useEffect, useState } from 'react';

export type TProps = {
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  // If true, the max value is exclusive
  lessThanMax?: boolean;
  minErrorMessages?: string[];
  maxErrorMessages?: string[];
  sizeVariant?: InputSizeVariant;
} & Omit<InputGroupInput, 'onChange'>;

const PercentageField: FC<TProps> = (props) => {
  const {
    value,
    onChange,
    inputClassName,
    min = 0,
    max = 100,
    lessThanMax = false,
    minErrorMessages,
    maxErrorMessages,
    sizeVariant,
    ...rest
  } = props;

  const [manualValue, setManualValue] = useState(value);
  const [validationState, setValidationState] =
    useState<FieldValidationState>();

  useEffect(() => {
    setManualValue(value);
  }, [value]);

  const handleManualValueChange = (value: string) => {
    setManualValue(value);

    const parsedValue = Number(value);
    if (isNaN(parsedValue) || parsedValue < min) {
      setValidationState({
        isValid: false,
        validationMessages: minErrorMessages,
      });
    } else if (lessThanMax && parsedValue >= max) {
      setValidationState({
        isValid: false,
        validationMessages: maxErrorMessages,
      });
    } else if (!lessThanMax && parsedValue > max) {
      setValidationState({
        isValid: false,
        validationMessages: maxErrorMessages,
      });
    } else {
      setValidationState(undefined);
      onChange(parsedValue);
    }
  };

  return (
    <Field.Legacy validationState={validationState}>
      <InputGroup sizeVariant={sizeVariant}>
        <InputGroup.Input
          type="number"
          inputClassName={clstx('pl-form-input--number', inputClassName)}
          value={manualValue}
          onChange={handleManualValueChange}
          {...rest}
        />
        <InputGroup.Icon name="percent-02.3" />
      </InputGroup>
    </Field.Legacy>
  );
};

export default PercentageField;
