import {
  useRegisterCityPlumbingPartnerApplication,
  useUnregisterCityPlumbingPartnerApplication,
} from '@/api/mutations/cityPlumbing/useRegisterCityPlumbingPartnerApplication';
import useGetSuppliers from '@/api/queries/suppliers/useGetSuppliers';
import { useSelector } from '@/api/state';
import {
  ConnectionControl,
  ConnectionControlBenefitList,
} from '@/ui/components';
import {
  registerFieldLegacyBuilder,
  zodHumanFriendlyFormErrorMap,
} from '@/utils/zod';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@payaca/components/plButton/Button';
import Field from '@payaca/components/plField/Field';
import RawInput from '@payaca/components/plInput/RawInput';
import Modal from '@payaca/components/plModal/Modal';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { FC, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { getUserRoles } from '../../../../utils/stateAccessors';
import CityPlumbingLogo from './CityPlumbingUK.svg';

const formSchema = z.object({
  accountNumber: z.string().min(1),
  accountPostcode: z.string().min(1),
  accountPhone: z.string().min(1),
});
type FormSchema = z.infer<typeof formSchema>;

const useCityPlumbingConnectionMetadata = (): {
  isLoading: boolean;
  connectionMetadata: Record<string, unknown> | undefined;
} => {
  const { suppliers: systemManagedSuppliers, isLoading } = useGetSuppliers({
    managedBy: ['SYSTEM'],
    searchTerm: 'City Plumbing',
  });
  const cityPlumbingSupplier = systemManagedSuppliers?.items?.find(
    ({ name, accountConnectionMetadata }) =>
      name === 'City Plumbing' && accountConnectionMetadata
  );
  return {
    isLoading,
    connectionMetadata: cityPlumbingSupplier?.accountConnectionMetadata,
  };
};

export const CityPlumbingConnectionControl: FC = () => {
  const [isSettingUpConnection, setIsSettingUpConnection] = useState(false);
  const registerConnection = useRegisterCityPlumbingPartnerApplication();
  const unregisterConnection = useUnregisterCityPlumbingPartnerApplication();
  const { connectionMetadata, isLoading } = useCityPlumbingConnectionMetadata();
  const hasConnection = connectionMetadata && !!connectionMetadata;
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    setError,
    control,
  } = useForm<FormSchema>({
    resolver: zodResolver(formSchema, {
      errorMap: zodHumanFriendlyFormErrorMap,
    }),
  });
  const registerField = registerFieldLegacyBuilder(errors);
  const onSubmit = async (data: FormSchema) => {
    const response = await registerConnection.mutateAsync(data);
    switch (response.registerCityPlumbingPartnerApplication.__typename) {
      case 'CityPlumbingPartnerApplicationSuccessResponse': {
        return setIsSettingUpConnection(false);
      }
      case 'CityPlumbingPartnerApplicationErrorAccountNotFound': {
        return setError('root', {
          type: 'server',
          message: 'Account not found. Please check your details.',
        });
      }
      default: {
        return setError('root', {
          type: 'server',
          message: 'An unknown error occurred, please try again later.',
        });
      }
    }
  };
  const userRoles = useSelector(getUserRoles);

  const canUpdate = useMemo(() => {
    return userHasRequiredPermission(userRoles, [
      AccountsPermissions.UPDATE_CONNECTION_AUTOMATION,
    ]);
  }, [userRoles]);

  let accountNumber = '[Unknown]';
  if (typeof connectionMetadata?.accountNumber === 'string') {
    accountNumber = connectionMetadata.accountNumber;
  }

  return (
    <ConnectionControl
      allowConnectDisconnect={canUpdate && !isLoading}
      connectionName="City Plumbing"
      isConnected={hasConnection}
      onAddConnection={() => setIsSettingUpConnection(true)}
      onRemoveConnection={async (onConnectionRemoved: () => void) => {
        await unregisterConnection.mutateAsync({});
        onConnectionRemoved();
      }}
      logo={CityPlumbingLogo}
    >
      {hasConnection ? (
        <p>
          Your City Plumbing trade account <strong>{accountNumber}</strong> is
          connected.
        </p>
      ) : (
        <>
          Connect Payaca with City Plumbing for a seamless flow of material
          data, simplifying quoting and procurement for your projects.
          <ConnectionControlBenefitList
            benefits={[
              'Search and add 80,000+ City Plumbing Materials to your Projects.',
              'View real-time prices specific to you.',
            ]}
          />
        </>
      )}
      <Modal
        isOpen={isSettingUpConnection}
        onClose={() => setIsSettingUpConnection(false)}
        title="Connect your City Plumbing trade account"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <p className="mb-4">
              Please enter the details that City Plumbing has on file for you.
              If you do not already have a City Plumbing trade account,{' '}
              <a href="https://www.cityplumbing.co.uk/register">
                register first
              </a>
              .
            </p>
            <div className="space-y-2.5">
              <Field.Legacy {...registerField('root')} />

              <Field.Legacy {...registerField('accountNumber')}>
                <Field.Label>Account Number</Field.Label>
                <RawInput
                  {...register('accountNumber')}
                  placeholder="Your City Plumbing trade account number"
                />
              </Field.Legacy>
              <Field.Legacy {...registerField('accountPostcode')}>
                <Field.Label>Postcode</Field.Label>
                <RawInput
                  {...register('accountPostcode')}
                  placeholder="The postcode your City Plumbing account has on file"
                />
              </Field.Legacy>
              <Field.Legacy {...registerField('accountPhone')}>
                <Field.Label>Phone Number</Field.Label>
                <RawInput
                  {...register('accountPhone')}
                  placeholder="The phone number your City Plumbing account has on file"
                />
              </Field.Legacy>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Modal.Footer.Actions>
              <Button type="submit" isProcessing={isSubmitting}>
                Connect
              </Button>
            </Modal.Footer.Actions>
          </Modal.Footer>
        </form>
      </Modal>
    </ConnectionControl>
  );
};
