import { useSelector } from '@/api/state';
import PurchaseOrderDocumentComponent, {
  PurchaseOrderDocumentSupplier,
} from '@payaca/components/purchaseOrderDocument/PurchaseOrderDocument';
import { getDocumentSenderContextFromAccount } from '@payaca/helpers/documentSenderContextHelper';
import { DocumentSenderContext } from '@payaca/types/accountBrandTypes';
import { PartialAddress } from '@payaca/types/locationTypes';
import { HydratedPurchaseOrder } from '@payaca/types/materialsListTypes';
import { FunctionComponent } from 'react';

type Props = {
  purchaseOrder: Omit<HydratedPurchaseOrder, 'supplier'> & {
    supplier: PurchaseOrderDocumentSupplier;
  };
  project?: {
    reference?: string;
  };
  documentSenderContext?: DocumentSenderContext;
  address?: PartialAddress;
};

const PurchaseOrderDocument: FunctionComponent<Props> = ({
  purchaseOrder,
  project,
  documentSenderContext,
  address,
}: Props): JSX.Element | null => {
  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  const orderType = purchaseOrder?.metadata?.orderType;

  if (!purchaseOrder) return null;

  return (
    <PurchaseOrderDocumentComponent
      project={project}
      documentSenderContext={
        documentSenderContext || getDocumentSenderContextFromAccount(account)
      }
      purchaseOrder={purchaseOrder}
      supplier={purchaseOrder.supplier}
      materialPurchaseRecords={purchaseOrder.materialPurchaseRecords}
      materialPurchaseIntents={purchaseOrder.materialPurchaseIntents}
      address={address}
      orderType={orderType}
    />
  );
};

export default PurchaseOrderDocument;
