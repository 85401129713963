import Badge from '@payaca/components/plBadge/Badge';
import Button from '@payaca/components/plButton/Button';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import CardButton from '@payaca/components/plCard/CardButton';
import SkeletonLoader from '@payaca/components/plSkeletonLoader/SkeletonLoader';
import UntitledIcon from '@payaca/untitled-icons';
import clsx from 'clsx';
import { FC, useState } from 'react';
import useGetMyRegionPreferences from '../../../../api/queries/me/useGetMyRegionPreferences';
import {
  CollectionFulfillmentOption,
  DeliveryFulfillmentOption,
  FulfillmentOption,
  getBadgeColour,
  getFromAvailabilityDate,
} from '../../../../helpers/fulfillment';
import SupplierBranchLink from '../../supplierBranchLink/SupplierBranchLink';

type ListedFulfillmentOptionsProps<T> = {
  options: T[];
  onClick: (option: T) => void;
  selectedFulfillmentOptionId?: string;
  renderCardBodyInner: (option: T) => JSX.Element;
  isLoading?: boolean;
};

const FulfillmentOptionCarrdSkeleton: FC = () => {
  return (
    <Card sizeVariant={CardSizeVariant.SM}>
      <Card.Body className="flex flex-col gap-4">
        <div className="flex row justify-between">
          <SkeletonLoader.Title className="w-1/3" />
          <SkeletonLoader.Title className="w-8" />
        </div>
        <SkeletonLoader.Text className="w-1/2" />
      </Card.Body>
    </Card>
  );
};

export const ListedFulfillmentOptions = <T extends FulfillmentOption>(
  props: ListedFulfillmentOptionsProps<T>
) => {
  const {
    options,
    onClick,
    selectedFulfillmentOptionId,
    renderCardBodyInner,
    isLoading,
  } = props;
  const [numberOfVisibleOptions, setNumberOfVisibleOptions] = useState(3);

  const visibleOptions = options.slice(0, numberOfVisibleOptions);

  const hasMoreHiddenOptions =
    visibleOptions.length && options.length > visibleOptions.length;

  if (isLoading) {
    return (
      <div className="space-y-4">
        <FulfillmentOptionCarrdSkeleton />
        <FulfillmentOptionCarrdSkeleton />
      </div>
    );
  }
  if (!visibleOptions?.length) {
    return <span>No options available</span>;
  }
  return (
    <div className="flex flex-col gap-4">
      {visibleOptions?.map((fulfillmentOption, i) => {
        return (
          <CardButton
            key={`fulfillment-option-${i}`}
            sizeVariant={CardSizeVariant.SM}
            className={clsx(
              'cursor-pointer border-0 ring-1 ring-blue-500/10',
              selectedFulfillmentOptionId ===
                fulfillmentOption.fulfillmentOptionId && 'ring-2 ring-blue-500'
            )}
            onClick={() => onClick(fulfillmentOption)}
          >
            <CardButton.Body>
              {renderCardBodyInner(fulfillmentOption)}
            </CardButton.Body>
          </CardButton>
        );
      })}
      {hasMoreHiddenOptions && (
        <Button
          onClick={() => setNumberOfVisibleOptions((n) => n + 3)}
          variant={EBtnVariant.LinkInline}
        >
          View more
        </Button>
      )}
    </div>
  );
};

export const DeliveryFulfillmentCardBodyInner: FC<DeliveryFulfillmentOption> = (
  fulfillmentOption
) => {
  const { data: regionPreferences } = useGetMyRegionPreferences();
  return (
    <>
      <div className="flex justify-between">
        <SupplierBranchLink branch={fulfillmentOption.branch} />
        <Badge colour={getBadgeColour(fulfillmentOption)} variant="soft">
          <UntitledIcon name="building-02.3" className="h-5 w-5" />
        </Badge>
      </div>
      <span>
        Delivery{' '}
        {getFromAvailabilityDate(
          fulfillmentOption.deliveryFromDate,
          false,
          regionPreferences?.locale
        )}
      </span>

      <FullPartialDetails
        isFullFulfillment={fulfillmentOption.isFullFulfillment}
        stock={fulfillmentOption.stock}
      />
    </>
  );
};

export const CollectionFulfillmentCardBodyInner: FC<
  CollectionFulfillmentOption
> = (fulfillmentOption) => {
  const { data: regionPreferences } = useGetMyRegionPreferences();
  return (
    <>
      <div className="flex justify-between">
        <SupplierBranchLink branch={fulfillmentOption.branch} />
        <Badge colour={getBadgeColour(fulfillmentOption)} variant="soft">
          <UntitledIcon name="building-02.3" className="h-5 w-5" />
        </Badge>
      </div>
      <span>
        Collection{' '}
        {getFromAvailabilityDate(
          fulfillmentOption.collectionFromDate,
          true,
          regionPreferences?.locale
        )}
      </span>
      <FullPartialDetails
        isFullFulfillment={fulfillmentOption.isFullFulfillment}
        stock={fulfillmentOption.stock}
      />
    </>
  );
};

type FullPartialDetailsProps = {
  isFullFulfillment: boolean;
  stock: FulfillmentOption['stock'];
};
const FullPartialDetails: FC<FullPartialDetailsProps> = (props) => {
  const { isFullFulfillment, stock } = props;
  if (isFullFulfillment) {
    return (
      <div className="flex gap-2 items-center">
        <UntitledIcon name="check" className="size-4 text-green-500" />
        <span>Full order in stock</span>
      </div>
    );
  }
  return (
    <div>
      <div className="flex gap-2 items-center">
        <UntitledIcon
          name="alert-triangle.3"
          className="size-4 text-yellow-500"
        />
        <span>Order partially out of stock - unavailable Materials:</span>
      </div>
      <div className="flex flex-col ml-6 italic text-sm">
        {stock.map((s, i) => {
          if (s.isFullMaterialFulfillment) {
            return;
          }
          return (
            <span key={i}>
              {s.requestedQuantity - s.availableQuantity} x {s.material.name}
            </span>
          );
        })}
      </div>
    </div>
  );
};
