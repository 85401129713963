import {
  autoPlacement,
  autoUpdate,
  offset,
  size,
  useFloating,
} from '@floating-ui/react';
import { Combobox as HUCombobox, Transition } from '@headlessui/react';
import UntitledIcon from '@payaca/untitled-icons';
import React, {
  ComponentProps,
  forwardRef,
  Fragment,
  PropsWithChildren,
  Ref,
  useContext,
  useMemo,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { FieldContext } from '../plField/Context';
import {
  Options,
  SelectButtonContent,
  SelectOption,
  Props as SelectProps,
  SelectSizeVariant,
  ValueType,
} from '../plSelect/Select';

export type Props<
  TValueType extends ValueType,
  TMultipleBoolean extends boolean,
  TMetaDataType = object,
> = Omit<
  SelectProps<TValueType, TMultipleBoolean, TMetaDataType>,
  'listboxOptionClassName'
> & {
  inputPlaceholder?: string;
  comboboxOptionClassName?: string;
  query?: string;
  setQuery?: (query: string) => void;
  filterFunction?:
    | ((
        option: SelectOption<TValueType, TMetaDataType>,
        query?: string
      ) => boolean)
    | null; // if filtering externally (ie asynchronously), set this to null explicitly
  onOptionClick?: (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    option: SelectOption<TValueType, TMetaDataType>
  ) => void;
};

const Combobox = <
  TValueType extends ValueType,
  TMultipleBoolean extends boolean,
  TMetaDataType = object,
>({
  options,
  onChange,
  onOptionClick,
  multiple,
  value,
  disabled,
  CustomOption,
  CustomSelected,
  placeholder,
  inputPlaceholder,
  children,
  sizeVariant = SelectSizeVariant.MD,
  className,
  query: externalQuery,
  setQuery: externalSetQuery,
  filterFunction = (o, q) => {
    return o.label.toLowerCase().includes(q?.toLowerCase() || '');
  },
  comboboxOptionClassName,
  customButtonContent,
  hideDropdownChevron = false,
  optionGroups,
}: PropsWithChildren<Props<TValueType, TMultipleBoolean, TMetaDataType>>) => {
  const { id, name, validationState } = useContext(FieldContext);
  const [query, setQuery] = useState('');
  const { refs, floatingStyles } = useFloating({
    transform: false,
    whileElementsMounted: autoUpdate,
    middleware: [
      autoPlacement({
        alignment: 'start',
        allowedPlacements: ['top-start', 'bottom-start'],
      }),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            maxWidth: `${rects.reference.width}px`,
          });
        },
        padding: 8,
      }),
      offset({
        mainAxis: 4,
      }),
    ],
  });

  const borderColour = useMemo(() => {
    switch (validationState?.isValid) {
      case true:
        return 'border-green-500';
      case false:
        return 'border-red-500';
      default:
        return 'border-gray-200';
    }
  }, [validationState?.isValid]);

  const sizeClasses = useMemo(() => {
    switch (sizeVariant) {
      case SelectSizeVariant.SM:
        return 'py-2 px-3 min-h-[34px]';
      case SelectSizeVariant.MD:
        return 'py-3 px-4';
      case SelectSizeVariant.LG:
        return 'py-3 px-4 sm:p-5';
    }
  }, [sizeVariant]);

  const selectedOptions = options.filter((o) => {
    return multiple
      ? (value as TValueType[])?.includes(o.value)
      : o.value === value;
  });

  const filteredOptions = options.filter((o) => {
    return filterFunction ? filterFunction(o, externalQuery || query) : true;
  });

  const close = () => {
    // @ts-ignore
    refs.reference?.current.click();
  };

  return (
    <HUCombobox
      // @ts-ignore
      multiple={multiple}
      disabled={disabled}
      onChange={onChange}
      value={value}
      name={name}
    >
      {({ open, ...rest }) => (
        <>
          <div className="relative block w-full" ref={refs.setReference}>
            <HUCombobox.Button
              // For some reason, the combobox.button component has tabIndex set to -1 by tefault and is therefore not navigable by tabbing.
              as={forwardRef(function AccessibleButton(
                props: ComponentProps<'button'>,
                ref: Ref<HTMLButtonElement>
              ) {
                return <button {...props} tabIndex={0} ref={ref} />;
              })}
              className={`${disabled ? 'pointer-events-none opacity-50' : ''} ${
                open ? 'hidden' : ''
              } text-pc-blue-darker font-inherit relative text-left shadow-sm disabled:text-inherit ${sizeClasses} block w-full border pr-9 sm:pr-9 ${borderColour} rounded-md bg-white text-base focus:border-blue-500 focus:ring-1 focus:ring-blue-500 dark:border-gray-700 dark:bg-slate-900 dark:text-gray-400 ${className}`}
            >
              {customButtonContent ? (
                customButtonContent
              ) : (
                <SelectButtonContent
                  multiple={multiple}
                  disabled={disabled}
                  value={value}
                  selectedOptions={selectedOptions}
                  CustomSelected={CustomSelected}
                  placeholder={placeholder}
                  onChange={onChange}
                />
              )}
              {!hideDropdownChevron && (
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <UntitledIcon
                    name="chevron-down"
                    className="h-5 w-5 text-gray-600"
                  />
                </span>
              )}
            </HUCombobox.Button>
            <HUCombobox.Input
              onChange={(event) =>
                externalSetQuery
                  ? externalSetQuery(event.target.value)
                  : setQuery(event.target.value)
              }
              placeholder={inputPlaceholder || 'Search'}
              className={`${
                open ? '' : 'hidden'
              } relative block w-full border border border-solid text-base ring-1 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 ${sizeClasses} ${borderColour} rounded-md bg-white`}
              value={externalQuery || query}
            />
          </div>
          {!!filteredOptions.length &&
            createPortal(
              <Transition
                show={open}
                appear
                as={Fragment}
                enter="transition duration-100 ease-out"
                enterFrom="scale-95 opacity-0"
                enterTo="scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="scale-100 opacity-100"
                leaveTo="scale-95 opacity-0"
              >
                <HUCombobox.Options
                  id={id}
                  ref={refs.setFloating}
                  className="z-dropdown m-0 block flex w-full list-none flex-col gap-1 overflow-y-auto rounded-md border border-gray-200 bg-white p-1 text-base focus:border-blue-500 focus:ring-1 focus:ring-blue-500 dark:border-gray-700 dark:bg-slate-900 dark:text-gray-400"
                  style={floatingStyles}
                >
                  <Options
                    options={filteredOptions}
                    optionGroups={optionGroups}
                    optionClassName={comboboxOptionClassName}
                    CustomOption={CustomOption}
                    OptionComponent={HUCombobox.Option}
                    onOptionClick={onOptionClick}
                    close={close}
                  />
                  {children}
                </HUCombobox.Options>
              </Transition>,
              document.body
            )}
        </>
      )}
    </HUCombobox>
  );
};

export default Combobox;
