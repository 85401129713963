import Button from '@payaca/components/plButton/Button';
import {
  EBtnColour,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import InputGroup from '@payaca/components/plInputGroup/InputGroup';
import Modal from '@payaca/components/plModal/Modal';
import Select, { SelectOption } from '@payaca/components/plSelect/Select';
import Tooltip from '@payaca/components/plTooltip/Tooltip';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import {
  getScheduledEventRemindersConfig,
  updateScheduledEventRemindersConfig,
} from '@payaca/store/account/accountActions';
import { UpdateScheduledEventRemindersConfigData } from '@payaca/store/account/accountTypes';
import {
  PublicScheduledEventReminderConfig,
  ScheduledEventReminderConfigTimeUnit,
  ScheduledEventReminderConfigType,
} from '@payaca/types/scheduledEventReminderConfigTypes';
import UntitledIcon from '@payaca/untitled-icons';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

type EventRemindersConfigFormState = {
  remindersConfig: UpdateScheduledEventRemindersConfigData;
};

export const ScheduledEventRemindersConfigForm: FC = () => {
  const dispatch = useDispatch();

  const [remindersConfig, setRemindersConfig] = useState<
    PublicScheduledEventReminderConfig[]
  >([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    dispatch(
      getScheduledEventRemindersConfig.request({
        callback: setRemindersConfig,
      })
    );
  }, []);

  const onSubmit = (formState: EventRemindersConfigFormState) => {
    setIsSubmitting(true);
    dispatch(
      updateScheduledEventRemindersConfig.request({
        remindersConfig: formState.remindersConfig,
        callback: (remindersConfig) => {
          setRemindersConfig(remindersConfig);
          setIsSubmitting(false);
        },
      })
    );
  };

  const initialFormState = useMemo(() => {
    return { remindersConfig };
  }, [remindersConfig]);

  return (
    <div>
      <ValidatedForm<EventRemindersConfigFormState>
        initialFormState={initialFormState}
        renderFormContents={(
          isValid,
          formState,
          validationState,
          touchedState,
          onFieldChange,
          onFieldTouch
        ) => {
          return (
            <FormContents
              formState={formState}
              onFieldChange={onFieldChange}
              isSubmitting={isSubmitting}
              onSubmit={onSubmit}
            />
          );
        }}
      />
    </div>
  );
};

const FormContents: FC<{
  formState: EventRemindersConfigFormState;
  onFieldChange: (value: Record<string, any>) => void;
  isSubmitting: boolean;
  onSubmit: (formState: EventRemindersConfigFormState) => void;
}> = ({ formState, onFieldChange, isSubmitting, onSubmit }) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="grid grid-cols-[200px_250px_200px_auto] items-center gap-2">
        <span className="font-semibold">Type</span>
        <span className="font-semibold">Time</span>
        <span className="font-semibold">Include description</span>
        <span></span>

        {formState.remindersConfig.map((reminderConfig, i) => (
          <ScheduledEventReminderConfigFields
            key={i}
            reminderConfig={reminderConfig}
            onChange={(value) => {
              const change: Record<string, any> = {};

              Object.entries(value).forEach(([key, value]) => {
                change[`remindersConfig[${i}].${key}`] = value;
              });
              onFieldChange(change);
            }}
            onRemove={() => {
              const remindersConfig = [...formState.remindersConfig];
              remindersConfig.splice(i, 1);
              onFieldChange({ remindersConfig });
            }}
          />
        ))}
      </div>
      <div>
        <Button
          onClick={() => {
            const remindersConfig = [...formState.remindersConfig];
            remindersConfig.push({
              type: 'email',
              timeUnit: 'days',
              unitsBeforeEventStart: 1,
              includeDescription: false,
            });
            onFieldChange({ remindersConfig });
          }}
          variant={EBtnVariant.LinkInline}
        >
          <UntitledIcon name="plus.3" className="h-4 w-4" />
          Add reminder
        </Button>
      </div>
      <div>
        <Button
          isProcessing={isSubmitting}
          onClick={() => {
            if (isSubmitting) return;
            onSubmit(formState);
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

const typeOptions: SelectOption<ScheduledEventReminderConfigType>[] = [
  { value: 'email', label: 'Email' },
  { value: 'sms', label: 'SMS' },
];

const unitOptions: SelectOption<ScheduledEventReminderConfigTimeUnit>[] = [
  { value: 'days', label: 'Days before' },
  { value: 'hours', label: 'Hours before' },
  { value: 'minutes', label: 'Minutes before' },
];

const includeDescriptionOptions: SelectOption<'true' | 'false'>[] = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];

const ScheduledEventReminderConfigFields: FC<{
  reminderConfig: EventRemindersConfigFormState['remindersConfig'][0];
  onChange: (
    value: Partial<EventRemindersConfigFormState['remindersConfig'][0]>
  ) => void;
  onRemove?: () => void;
}> = ({ reminderConfig, onChange, onRemove }) => {
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  return (
    <>
      <Select
        className="max-w-[200px]"
        options={typeOptions}
        value={reminderConfig.type}
        onChange={(value) => {
          const change: Record<string, any> = {
            type: value as ScheduledEventReminderConfigType,
          };
          if (value === 'sms') {
            change.includeDescription = false;
          }
          onChange(change);
        }}
      />
      <InputGroup sizeVariant="md">
        <InputGroup.Input
          className="max-w-[80px]"
          value={reminderConfig.unitsBeforeEventStart.toString()}
          type="numeric"
          onChange={(value) => {
            onChange({ unitsBeforeEventStart: isNaN(+value) ? 0 : +value });
          }}
        />
        <InputGroup.Select
          options={unitOptions}
          value={reminderConfig.timeUnit}
          onChange={(value) => {
            onChange({
              timeUnit: value as ScheduledEventReminderConfigTimeUnit,
            });
          }}
        />
      </InputGroup>
      {reminderConfig.type === 'sms' ? (
        <Tooltip tooltipContent="Including descriptions is only available for email reminders.">
          <Select
            className="max-w-[200px]"
            disabled={reminderConfig.type === 'sms'}
            options={includeDescriptionOptions}
            value={reminderConfig.includeDescription ? 'true' : 'false'}
            onChange={(value) => {
              onChange({ includeDescription: value === 'true' });
            }}
          />
        </Tooltip>
      ) : (
        <Select
          className="max-w-[200px]"
          options={includeDescriptionOptions}
          value={reminderConfig.includeDescription ? 'true' : 'false'}
          onChange={(value) => {
            onChange({ includeDescription: value === 'true' });
          }}
        />
      )}

      <span className="ml-6">
        {onRemove && (
          <Button
            variant={EBtnVariant.Ghost}
            onClick={() => setShowConfirmDeleteModal(true)}
          >
            <UntitledIcon name="x-close" className="h-4 w-4" />
          </Button>
        )}
        <Modal
          isOpen={showConfirmDeleteModal}
          onClose={() => setShowConfirmDeleteModal(false)}
          title="Are you sure you want to delete this reminder"
        >
          <Modal.Body>
            <div className="flex flex-row gap-4">
              <Button
                variant={EBtnVariant.Outline}
                onClick={() => {
                  setShowConfirmDeleteModal(false);
                }}
              >
                Back
              </Button>
              <Button
                colour={EBtnColour.Red}
                onClick={() => {
                  onRemove?.();
                }}
              >
                Delete reminder
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </span>
    </>
  );
};
