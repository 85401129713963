import PurchaseOrderDocument from '@payaca/components/purchaseOrderDocument/PurchaseOrderDocument';
import * as pdfDocumentDataActions from '@payaca/store/pdfDocumentData/pdfDocumentDataActions';
import { PurchaseOrderPdfDocumentData } from '@payaca/types/purchaseOrderPdfDocumentTypes';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import './PurchaseOrderPage.sass';

type Props = {
  previewToken: string;
};

const PurchaseOrderPage: FC<Props> = ({
  previewToken,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const [purchaseOrderData, setPurchaseOrderData] =
    useState<PurchaseOrderPdfDocumentData>();

  const handlePurchaseOrderData = useCallback(
    (purchaseOrderData: PurchaseOrderPdfDocumentData) => {
      setPurchaseOrderData(purchaseOrderData);
    },
    []
  );

  useEffect(() => {
    dispatch(
      pdfDocumentDataActions.requestGetPurchaseOrderData(
        previewToken,
        handlePurchaseOrderData
      )
    );
  }, []);

  if (!purchaseOrderData) return null;
  return (
    <div className="purchase-order-page">
      <PurchaseOrderDocument
        project={purchaseOrderData.project}
        orderType={purchaseOrderData.deliveryAddress ? 'delivery' : undefined}
        address={purchaseOrderData.deliveryAddress}
        documentSenderContext={purchaseOrderData.documentSenderContext}
        purchaseOrder={purchaseOrderData.purchaseOrder}
        materialPurchaseRecords={purchaseOrderData.materialPurchaseRecords}
        materialPurchaseIntents={purchaseOrderData.materialPurchaseIntents}
        supplier={purchaseOrderData.supplier}
      />
    </div>
  );
};

export default PurchaseOrderPage;
