import { FC } from 'react';

export const FieldError: FC<{ messages: string[] }> = (props) => {
  const { messages } = props;

  return (
    <ul className="m-0 mt-2 list-none p-0">
      {messages.map((message, i) => (
        <li key={i} className="mb-2 last:mb-0">
          <p className="m-0 text-base text-red-500">{message}</p>
        </li>
      ))}
    </ul>
  );
};
