import React from 'react';

export const FieldContext = React.createContext<{
  validationState?: FieldValidationState;
  id?: string;
  name?: string;
}>({});

export type FieldValidationState = {
  isValid: boolean;
  validationMessages?: string[];
};
