import useDeleteChangeProposal from '@/api/mutations/proposals/useDeleteChangeProposal';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnColour,
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import Input from '@payaca/components/plInput/Input';
import { DealsPermissions } from '@payaca/permissions/deals/deals.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { ProposalsPermissions } from '@payaca/permissions/proposals/proposals.permissions';
import { useChangeProposal } from '@payaca/store/hooks/appState';
import {
  requestGetChangeProposal,
  requestPersistChangeProposal,
} from '@payaca/store/proposals/proposalsActions';
import { ChangeProposal } from '@payaca/types/changeProposalTypes';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useSelector } from '../../../api/state';
import { getUserRoles } from '../../../utils/stateAccessors';
import ChangeProposalValueSummary from '../changeProposalValueSummary/ChangeProposalValueSummary';
import DealPaymentOptionsControl from '../dealPaymentOptionsControl/DealPaymentOptionsControl';
import DealPriceDetailControl from '../dealPriceDetailControl/DealPriceDetailControl';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import { ProjectAccountingIntegrationCategoriesControl } from '../projectAccountingIntegrationCategoriesControl/ProjectAccountingIntegrationCategoriesControl';
import './ChangeProposalSidePanel.sass';

type Props = {
  changeProposalId: number;
};

const ChangeProposalSidePanel: FC<Props> = ({
  changeProposalId,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userRoles = useSelector(getUserRoles);

  /**
   * Mutations
   */
  const {
    mutateAsync: deleteChangeProposal,
    isLoading: isDeletingChangeProposal,
  } = useDeleteChangeProposal();

  const changeProposal: ChangeProposal | undefined =
    useChangeProposal(changeProposalId);

  const [expiresInDays, setExpiresInDays] = useState<number>();
  const [isSaveRequired, setIsSaveRequired] = useState(false);

  useEffect(() => {
    if (changeProposal?.id) {
      setExpiresInDays(changeProposal?.expiresInDays);
    }
  }, [changeProposal?.id]);

  useEffect(() => {
    if (isSaveRequired) {
      setIsSaveRequired(false);
      dispatch(
        requestPersistChangeProposal(
          {
            id: changeProposalId,
            expiresInDays: expiresInDays,
          },
          () => {
            dispatch(requestGetChangeProposal(changeProposalId));
          }
        )
      );
    }
  }, [isSaveRequired]);

  const canBeDeleted = changeProposal?.jobLineItemIds.length === 0;

  const handleDeleteChangeProposal = async () => {
    if (!canBeDeleted) {
      return;
    }

    await deleteChangeProposal({
      changeProposalId: changeProposalId.toString(),
    });

    history.push(`/deals/${changeProposal.dealId}/proposals`);
  };

  return (
    <div className="change-proposal-side-panel mb-8">
      <div className="change-proposal-expire-days-wrapper">
        Change proposal expires in{' '}
        <Input
          className="w-[70px] inline-block align-middle"
          sizeVariant="sm"
          name="expiresInDays"
          value={expiresInDays}
          type="number"
          onChange={(value) => {
            let v = undefined;

            if (!isNaN(Number(value)) && value !== '') {
              v = Math.round(Number(value));
            }
            setExpiresInDays(v);
          }}
          changeTimeoutMs={2000}
          onBlur={() => setIsSaveRequired(true)}
          onChangeTimeout={() => setIsSaveRequired(true)}
          disabled={
            !userHasRequiredPermission(userRoles, [
              ProposalsPermissions.EDIT_PROPOSAL_EXPIRES_IN_DAYS,
            ])
          }
        />{' '}
        days
      </div>
      <ChangeProposalValueSummary changeProposalId={changeProposalId} />
      {changeProposal && (
        <>
          <PermissionGuard
            renderIfHasPermissions={[DealsPermissions.MANAGE_PAYMENT_OPTIONS]}
          >
            <DealPaymentOptionsControl dealId={changeProposal?.dealId} />
          </PermissionGuard>
          <PermissionGuard
            renderIfHasPermissions={[DealsPermissions.MANAGE_PRICE_VISIBILITY]}
          >
            <DealPriceDetailControl
              dealId={changeProposal?.dealId}
              canHideVat={true}
            />
          </PermissionGuard>
          <ProjectAccountingIntegrationCategoriesControl
            projectId={changeProposal.dealId}
          />
          {canBeDeleted && (
            <PermissionGuard
              renderIfHasPermissions={[
                ProposalsPermissions.DELETE_UNSENT_CHANGE_PROPOSAL,
              ]}
            >
              <Button
                onClick={handleDeleteChangeProposal}
                size={EBtnSize.Small}
                variant={EBtnVariant.Ghost}
                colour={EBtnColour.Red}
                disabled={isDeletingChangeProposal}
                isProcessing={isDeletingChangeProposal}
              >
                Delete
              </Button>
            </PermissionGuard>
          )}
        </>
      )}
    </div>
  );
};
export default ChangeProposalSidePanel;
