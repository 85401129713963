/**
 * For "standard" purchase orders
 */
import { useSelector } from '@/api/state';
import ConfirmPurchaseOrderModal from '@/ui/components/confirmPurchaseOrderModal/ConfirmPurchaseOrderModal';
import EditPurchaseOrderModal from '@/ui/components/editPurchaseOrderModal/EditPurchaseOrderModal';
import PreviewDocument from '@/ui/components/PreviewQuoteInvoice/PreviewDocument';
import PurchaseOrderDocument from '@/ui/components/purchaseOrderDocument/PurchaseOrderDocument';
import SendPurchaseOrderModal from '@/ui/components/sendPurchaseOrderModal/SendPurchaseOrderModal';
import PurchaseOrderPageSkeletonLoader from '@/ui/pages/purchaseOrderPage/PurchaseOrderPageSkeletonLoader';
import { useHashFragment } from '@/utils/customHooks';
import { getUserRoles } from '@/utils/stateAccessors';
import { EAlertColour } from '@payaca/components/plAlert/Alert';
import Card from '@payaca/components/plCard/Card';
import { TDropdownItem } from '@payaca/components/plDropdown/DropdownCore';
import {
  DateFormats,
  getInternationalMomentDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';
import { MaterialsListPermissions } from '@payaca/permissions/materialsList/materialsList.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { useDeal } from '@payaca/store/hooks/appState';
import { requestGetMaterialsListWithRelatedEntities } from '@payaca/store/materialsList/materialsListActions';
import { HydratedPurchaseOrder } from '@payaca/types/materialsListTypes';
import UntitledIcon from '@payaca/untitled-icons';
import moment from 'moment-timezone';
import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import useGetPurchaseOrderDeliveryAddress from '../../../../api/queries/purchase-orders/useGetPurchaseOrderAddress';
import { getIndirectPurchaseOrderBadgeConfig } from '../../../../helpers/purchaseOrders';
import VoidPurchaseOrderModal from './VoidPurchaseOrderModal';

type Props = {
  purchaseOrder?: HydratedPurchaseOrder;
  projectId?: number;
  getPurchaseOrder?: () => void;
  fetchPdfRetry: () => void;
};
const IndirectPurchaseOrder: FC<Props> = (props) => {
  const { purchaseOrder, getPurchaseOrder, projectId, fetchPdfRetry } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const userRoles = useSelector(getUserRoles);
  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  const deal = useDeal(projectId || undefined);

  const [showConfirmPurchaseOrderModal, toggleConfirmPurchaseOrderModal] =
    useHashFragment('#confirm-po');
  const [showSendPurchaseOrderModal, toggleSendPurchaseOrderModal] =
    useHashFragment('#send-po');
  const [
    showConfirmVoidPurchaseOrderModal,
    toggleConfirmVoidPurchaseOrderModal,
  ] = useHashFragment('#void-po');
  const [showEditPurchaseOrderModal, toggleEditPurchaseOrderModal] =
    useHashFragment('#edit-po');

  const sentAtTime = moment(purchaseOrder?.sentAt).format(
    getInternationalMomentDateFormatByRegion(DateFormats.SHORT, account.region)
  );

  const purchaseOrderBadgeConfig = getIndirectPurchaseOrderBadgeConfig(
    purchaseOrder?.status
  );

  const { data: deliveryAddress } = useGetPurchaseOrderDeliveryAddress(
    purchaseOrder?.id
  );

  const primaryAction = useMemo(() => {
    if (purchaseOrder?.voidedAt) {
      return;
    }

    if (
      !purchaseOrder?.confirmedAt &&
      purchaseOrder?.sentAt &&
      userHasRequiredPermission(userRoles, [
        MaterialsListPermissions.CONFIRM_PURCHASE_ORDER,
      ])
    ) {
      return {
        children: 'Confirm purchases',
        onClick: () => toggleConfirmPurchaseOrderModal(),
      };
    }

    if (
      !purchaseOrder?.sentAt &&
      userHasRequiredPermission(userRoles, [
        MaterialsListPermissions.SEND_PURCHASE_ORDER,
      ])
    ) {
      return {
        children: 'Send',
        onClick: () => toggleSendPurchaseOrderModal(),
      };
    }

    if (purchaseOrder?.pdfUrl) {
      return {
        children: <UntitledIcon name="download-01.3" className="h-5 w-5" />,
        onClick: () => {
          // todo: anchor link as a context menu item
          window.open(purchaseOrder.pdfUrl, '_blank');
        },
      };
    }
  }, [purchaseOrder, userRoles]);

  const availableContextMenuItems = useMemo(() => {
    const actions: TDropdownItem[] = [];
    if (purchaseOrder?.voidedAt) {
      return actions;
    }

    if (
      !purchaseOrder?.sentAt &&
      userHasRequiredPermission(userRoles, [
        MaterialsListPermissions.PERSIST_PURCHASE_ORDER,
      ])
    ) {
      actions.push({
        label: 'Edit',
        onClick: () => {
          if (!purchaseOrder) return;

          if (purchaseOrder.isIsolated) {
            history.push(`/purchaseorders/${purchaseOrder.id}/edit`);
          } else {
            dispatch(
              requestGetMaterialsListWithRelatedEntities(
                purchaseOrder.materialsListId
              )
            );
            toggleEditPurchaseOrderModal();
          }
        },
      });
    }

    if (
      userHasRequiredPermission(userRoles, [
        MaterialsListPermissions.VOID_PURCHASE_ORDER,
      ])
    ) {
      actions.push({
        label: 'Void this purchase order',
        onClick: () => toggleConfirmVoidPurchaseOrderModal(),
      });
    }

    if (
      purchaseOrder?.sentAt &&
      !purchaseOrder.confirmedAt &&
      purchaseOrder.pdfUrl
    ) {
      actions.push({
        label: 'Download',
        onClick: () => {
          window.open(purchaseOrder.pdfUrl, '_blank');
        },
      });
    }

    return actions;
  }, [userRoles, purchaseOrder, purchaseOrder?.id]);

  return (
    <>
      <PreviewDocument
        title={`Purchase Order ${purchaseOrder?.reference || ''}: ${
          purchaseOrder?.supplier?.name || ''
        }`}
        badge={{
          variant: purchaseOrderBadgeConfig.variant,
          colour: purchaseOrderBadgeConfig.colour,
          children: purchaseOrderBadgeConfig.label,
        }}
        firstAction={primaryAction}
        actions={availableContextMenuItems}
        alerts={
          purchaseOrder?.sentAt
            ? [
                {
                  colour: EAlertColour.SOFT_YELLOW,
                  children: `This Purchase Order was ${
                    purchaseOrder?.isSentOffline ? 'marked as' : ''
                  } sent on ${sentAtTime}`,
                },
              ]
            : undefined
        }
      >
        {purchaseOrder ? (
          <Card>
            <Card.Body>
              <PurchaseOrderDocument
                purchaseOrder={purchaseOrder}
                project={
                  deal
                    ? {
                        reference:
                          deal.customReference || deal.reference?.toString(),
                      }
                    : undefined
                }
                address={deliveryAddress || undefined}
              />
            </Card.Body>
          </Card>
        ) : (
          <PurchaseOrderPageSkeletonLoader />
        )}
      </PreviewDocument>
      {!!purchaseOrder && (
        <>
          {!purchaseOrder.confirmedAt && purchaseOrder.sentAt && (
            <ConfirmPurchaseOrderModal
              purchaseOrder={purchaseOrder}
              isOpen={showConfirmPurchaseOrderModal}
              onClose={() => toggleConfirmPurchaseOrderModal()}
              onConfirmPurchaseOrderSuccess={() => {
                toggleConfirmPurchaseOrderModal();
                getPurchaseOrder?.();
              }}
            />
          )}
          {!purchaseOrder.sentAt && (
            <>
              <SendPurchaseOrderModal
                isOpen={showSendPurchaseOrderModal}
                onClose={() => toggleSendPurchaseOrderModal()}
                purchaseOrder={purchaseOrder}
                onSendPurchaseOrderSuccess={() => {
                  toggleSendPurchaseOrderModal();
                  getPurchaseOrder?.();
                }}
              />
              <EditPurchaseOrderModal
                projectId={projectId ? +projectId : undefined}
                isOpen={showEditPurchaseOrderModal}
                onClose={() => toggleEditPurchaseOrderModal()}
                purchaseOrderId={purchaseOrder.id}
                onEditPurchaseOrderSuccess={() => {
                  toggleEditPurchaseOrderModal();
                  getPurchaseOrder?.();
                  void fetchPdfRetry();
                }}
              />
            </>
          )}
          <VoidPurchaseOrderModal
            isOpen={showConfirmVoidPurchaseOrderModal}
            onClose={() => toggleConfirmVoidPurchaseOrderModal()}
            purchaseOrderId={purchaseOrder.id}
            onVoidSuccess={() => {
              getPurchaseOrder?.();
              toggleConfirmVoidPurchaseOrderModal();
            }}
          />
        </>
      )}
    </>
  );
};

export default IndirectPurchaseOrder;
