import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import supplierKeys from './keyFactory';

const GetSupplierBranches = graphql(`
  query GetSupplierBranches($id: ID!) {
    supplier(id: $id) {
      id
      name
      branches {
        id
        name
        address {
          fullLocalAddress
          line1
          line2
          city
          postcode
          country
          coordinates {
            latitude
            longitude
          }
        }
        operatingHours
        url
        email
        phone
      }
    }
  }
`);

const useGetSupplierBranches = (supplierId?: string, enabled?: boolean) => {
  const { data, ...rest } = useQuery({
    queryKey: supplierKeys.branches(supplierId!),
    queryFn: () => {
      return gqlClient.request(GetSupplierBranches, {
        id: supplierId!,
      });
    },
    staleTime: 24 * 60 * 60 * 1000, // 24 hours
    enabled: !!supplierId && enabled,
  });

  return { branches: data?.supplier.branches, data, ...rest };
};

export default useGetSupplierBranches;
