import useGetMyRegionPreferences from '@/api/queries/me/useGetMyRegionPreferences';
import Button from '@payaca/components/plButton/Button';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import Card from '@payaca/components/plCard/Card';
import Checkbox from '@payaca/components/plCheckbox/Checkbox';
import { FieldValidationState } from '@payaca/components/plField/Context';
import Field from '@payaca/components/plField/Field';
import CurrencyInput from '@payaca/components/plInput/CurrencyInput';
import Input from '@payaca/components/plInput/Input';
import RichTextarea from '@payaca/components/plRichTextarea/RichTextarea';
import Table from '@payaca/components/plTable/Table';
import Tooltip from '@payaca/components/plTooltip/Tooltip';
import {
  PaymentSchedule,
  PaymentScheduleStage,
} from '@payaca/types/payment-schedule';
import UntitledIcon from '@payaca/untitled-icons';
import React, { FC, useMemo, useState } from 'react';

export type PaymentScheduleStageInput = Omit<
  PaymentScheduleStage,
  'percentageDue'
> & {
  percentageDue: string | number;
};

export type PaymentScheduleInput = Omit<PaymentSchedule, 'stages'> & {
  stages: PaymentScheduleStageInput[];
};

type Props = {
  predictedProjectValue?: number;
  paymentSchedule?: PaymentScheduleInput;
  onChange: (change: PaymentScheduleInput) => void;
  fieldValidationState?: FieldValidationState;
};

const CreatePaymentScheduleControl: FC<Props> = ({
  predictedProjectValue,
  paymentSchedule,
  onChange,
  fieldValidationState,
}: Props) => {
  const [newStageIndex, setNewStageIndex] = React.useState<number>();
  const [showExplainerInput, setShowExplainerInput] = useState<boolean>(
    !!paymentSchedule?.explainer
  );

  const { data: regionPreferences } = useGetMyRegionPreferences();

  const onRemovePaymentScheduleStage = (rowIndex: number) => {
    // remove row from payment schedule
    onChange({
      stages:
        paymentSchedule?.stages.filter(
          (_r: PaymentScheduleStageInput, i: number) => i !== rowIndex
        ) || [],
      explainer: paymentSchedule?.explainer,
    });
  };

  const onAddPaymentScheduleStage = () => {
    // add row to payment schedule
    onChange({
      stages: [
        ...(paymentSchedule?.stages || []),
        {
          description: '',
          percentageDue: remainingPercentage,
        },
      ],
      explainer: paymentSchedule?.explainer,
    });
    setNewStageIndex(paymentSchedule?.stages.length || 0);
  };

  const onUpdatePaymentScheduleStage = (
    change: {
      [K in keyof PaymentScheduleStageInput]?: PaymentScheduleStageInput[K];
    },
    rowIndex: number
  ) => {
    // update a change on specific row
    onChange({
      stages:
        paymentSchedule?.stages.map(
          (r: PaymentScheduleStageInput, i: number) => {
            return rowIndex === i ? { ...r, ...change } : r;
          }
        ) || [],
      explainer: paymentSchedule?.explainer,
    });
  };

  const isPercentagesSumMoreThan100 = useMemo(
    () =>
      paymentSchedule &&
      paymentSchedule.stages.reduce(
        (acc, curr) => acc + +curr.percentageDue,
        0
      ) > 100,
    [paymentSchedule]
  );

  const remainingPercentage = useMemo(() => {
    return (
      100 -
      (paymentSchedule?.stages.reduce(
        (acc, curr) => acc + +curr.percentageDue,
        0
      ) || 0)
    );
  }, [paymentSchedule]);

  const isPercentageDueLessThanZero = (percentageDue: number) =>
    percentageDue < 0;
  const isPercentageDueEmpty = (percentageDue: number) => !percentageDue;

  return (
    <>
      <Field.Legacy
        validationState={fieldValidationState}
        name="paymentScheduleStages"
      >
        <Field.Label>Payment schedule</Field.Label>
        <Card>
          <Table<PaymentScheduleStageInput>
            data={paymentSchedule?.stages || []}
            hideEmptyState
            footerRow={
              <>
                <Button
                  variant={EBtnVariant.Link}
                  onClick={onAddPaymentScheduleStage}
                  className="focus:ring-0"
                >
                  <UntitledIcon name="plus.3" className="2-4 h-4" />
                  Add stage ({Math.max(remainingPercentage, 0)}% remaining)
                </Button>
              </>
            }
          >
            <Table.Column<PaymentScheduleStage, 'description'>
              className="w-full"
              field="description"
              header="Stage"
              headerClassName="uppercase"
              render={(field, _row, rowIndex) => {
                return (
                  <Field.Legacy
                    validationState={
                      !field && newStageIndex !== rowIndex
                        ? {
                            isValid: false,
                          }
                        : undefined
                    }
                  >
                    <Input
                      value={field}
                      onChange={(value) =>
                        onUpdatePaymentScheduleStage(
                          { description: value },
                          rowIndex
                        )
                      }
                    />
                  </Field.Legacy>
                );
              }}
            />
            <Table.Column<PaymentScheduleStageInput, 'percentageDue'>
              field="percentageDue"
              header="Percentage"
              headerClassName="uppercase"
              render={(field, _row, rowIndex) => {
                return (
                  <Field.Legacy
                    validationState={
                      isPercentagesSumMoreThan100 ||
                      isPercentageDueLessThanZero(+field) ||
                      (isPercentageDueEmpty(+field) &&
                        newStageIndex !== rowIndex)
                        ? {
                            isValid: false,
                          }
                        : undefined
                    }
                  >
                    <Input
                      className="w-[100px]"
                      value={field ? `${field}` : ''}
                      onChange={(value) => {
                        onUpdatePaymentScheduleStage(
                          { percentageDue: value },
                          rowIndex
                        );
                      }}
                      type="number"
                    />
                  </Field.Legacy>
                );
              }}
            />
            {predictedProjectValue && (
              <Table.Column<PaymentScheduleStageInput, 'percentageDue'>
                field="percentageDue"
                header="Amount"
                headerClassName="uppercase"
                render={(field, _row, rowIndex) => {
                  return (
                    <Field.Legacy>
                      <div className="w-[150px]">
                        <CurrencyInput
                          className="w-[200px]"
                          value={predictedProjectValue * (+field / 100)}
                          onChange={(value) => {
                            const percentage =
                              (value / predictedProjectValue) * 100;

                            onUpdatePaymentScheduleStage(
                              { percentageDue: percentage },
                              rowIndex
                            );
                          }}
                          currency={regionPreferences?.currency}
                        />
                      </div>
                    </Field.Legacy>
                  );
                }}
              />
            )}
            <Table.Column<PaymentScheduleStageInput, 'isDeposit'>
              field="isDeposit"
              header="Deposit?"
              headerClassName="uppercase"
              render={(field, _row, rowIndex) => {
                // deposit checkbox only shown on first row - only one and first payment
                if (rowIndex === 0) {
                  return (
                    <Checkbox
                      checked={!!field}
                      onChange={() =>
                        onUpdatePaymentScheduleStage(
                          { isDeposit: !field },
                          rowIndex
                        )
                      }
                    />
                  );
                }
              }}
            />

            <Table.Column
              field="adjust"
              header="Adjust"
              className="p-0"
              headerClassName="uppercase"
              hiddenHeader
              render={(_field, _row, rowIndex) => {
                return (
                  <Tooltip
                    tooltipContent={
                      <div className="max-w-[200px]">
                        Adjust the percentage of this stage{' '}
                        {remainingPercentage < 0 ? 'down' : 'up'} to bring the
                        total closer to 100%
                      </div>
                    }
                  >
                    <Button
                      disabled={remainingPercentage === 0}
                      variant={EBtnVariant.Link}
                      onClick={() =>
                        onUpdatePaymentScheduleStage(
                          {
                            percentageDue: Math.max(
                              +_row.percentageDue + remainingPercentage,
                              0
                            ),
                          },
                          rowIndex
                        )
                      }
                    >
                      <UntitledIcon
                        name={
                          remainingPercentage < 0 ? 'arrow-down' : 'arrow-up'
                        }
                        className="w-6 h-6"
                      />
                    </Button>
                  </Tooltip>
                );
              }}
            />

            <Table.Column
              field="remove"
              header="Remove"
              className="p-0"
              headerClassName="uppercase"
              hiddenHeader
              render={(_field, _row, rowIndex) => {
                return (
                  <Button
                    variant={EBtnVariant.Link}
                    onClick={() => onRemovePaymentScheduleStage(rowIndex)}
                  >
                    Remove
                  </Button>
                );
              }}
            />
          </Table>
        </Card>
      </Field.Legacy>
      <Field.Legacy name="paymentScheduleExplainer">
        <div className="mt-4">
          {!showExplainerInput && (
            <Button
              variant={EBtnVariant.Link}
              onClick={() => setShowExplainerInput(true)}
              className="focus:ring-0"
            >
              <UntitledIcon name="plus.3" className="2-4 h-4" />
              Add explainer
            </Button>
          )}
          {showExplainerInput && (
            <>
              <Field.Label>Payment schedule explainer text</Field.Label>
              <RichTextarea
                value={paymentSchedule?.explainer || ''}
                onChange={(value) =>
                  onChange({
                    ...paymentSchedule,
                    stages: paymentSchedule?.stages || [],
                    explainer: value,
                  })
                }
              />
            </>
          )}
        </div>
      </Field.Legacy>
    </>
  );
};

export default CreatePaymentScheduleControl;
