import {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  useRef,
} from 'react';
import RawInput, { InputIcon, TProps as TRawProps } from './RawInput';

export type TProps = Omit<TRawProps, 'onChange' | 'onTouch'> & {
  onChange?: (value: string) => void;
  onTouch?: () => void;
  changeTimeoutMs?: number;
  onChangeTimeout?: (value: string) => void;
};

const Input = forwardRef<HTMLInputElement, TProps>(
  (
    {
      onChange,
      onTouch,
      onChangeTimeout,
      changeTimeoutMs = 1000,
      onFocus,
      type = 'text',
      ...rest
    },
    ref
  ) => {
    const changeTimeout = useRef<number | null>(null);

    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      onChange?.(e.target.value);

      if (changeTimeout?.current) {
        clearTimeout(changeTimeout.current);
      }
      changeTimeout.current = (setTimeout as any)(() => {
        if (onChangeTimeout) {
          onChangeTimeout(e.target.value);
        }
      }, changeTimeoutMs);
    };

    const handleTouch: FocusEventHandler<HTMLInputElement> = (e) => {
      onTouch?.();
      // Normal on focus event
      onFocus?.(e);
    };

    return (
      <RawInput
        ref={ref}
        type={type}
        onChange={handleChange}
        onFocus={handleTouch}
        {...rest}
      />
    );
  }
);

export default Input;

export { InputIcon };
