import { nanoid } from 'nanoid';
import {
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useRef,
} from 'react';
import { clstx } from '../utils';
import { FieldContext, FieldValidationState } from './Context';
import { FieldError } from './FieldError';

type Props = {
  validationState?: FieldValidationState;
  id?: string;
  name?: string;
  className?: string;
};

const FieldLegacy: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  validationState,
  name,
  className,
  id: customId,
}) => {
  const { current: id } = useRef(customId || nanoid());

  return (
    <FieldContext.Provider
      value={{
        validationState,
        id,
        name,
      }}
    >
      <div className={clstx('prose', className)}>
        {children}

        <FieldValidationMessages />
      </div>
    </FieldContext.Provider>
  );
};

const FieldValidationMessages: FunctionComponent = () => {
  const { validationState } = useContext(FieldContext);
  return <ValidationMessages validationState={validationState} />;
};

export const ValidationMessages: FunctionComponent<{
  validationState?: FieldValidationState;
}> = ({ validationState }) => {
  const validationMessages = validationState?.validationMessages;

  if (!validationMessages?.length) return null;

  return <FieldError messages={validationMessages} />;
};

export default FieldLegacy;
